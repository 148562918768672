import React from "react";
import PracticesService, {
  IPractice,
  PracticeUpdateTypes,
} from "../../Services/PracticesService";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, Col, Row, Spin, Image, Tabs, TabsProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./Practices.css";
import { useForm } from "antd/es/form/Form";
import { adminContext } from "../../Common/PageRoute";
import NoImageToShow from "../../images/no_Image_to_show.png";
import dayjs from "dayjs";
import BasicInformation from "./BasicInformation";
import BankAccount from "./BankAccount";
import TimeZoneVATAndEmail from "./TimeZoneVATAndEmail";
import PracticeSequence from "./PracticeSequence";
import ReviewRequest from "./ReviewRequest";
import Features from "./Features";
import ImportantBadges from "./ImportantBadges";
const defaultData = {
  id: "",
  address: {
    building: "",
    city: "",
    country: "",
    county: "",
    postcode: "",
    street: "",
  },
  params: {
    dueInvoicesTaskDays: 0,
    pageTrackTimeout: 0,
    taskDueDateInDays: 0,
  },
  appFeature: {
    accounts: false,
    aml: false,
    bankStatement: false,
    bookkeeping: false,
    calls: false,
    chats: false,
    clientDataRequest: false,
    corporationTax: false,
    dayEnd: false,
    leadSources: false,
    linkedinLeads: false,
    payroll: false,
    teams: false,
    webForms: false,
    timesheet: false,
    careOf: false,
    selfAssessment: false,
    dms: false,
    other4: false,
    other5: false,
  },
  appSubFeature: {
    actionTab: false,
    addCustomerWithoutLead: false,
    digitalSignatureWithoutAML: false,
    manualTimesheet: false,
    progressiveLeads: false,
    soaIsEnable: false,
    soaIsNotificationEnable: false,
    websiteQueriesWithOTP: false,
    witoutDiscount: false,
    clientAutomation: false,
    proofOfEarningFPS: false,
    receiptAttachments: false,
    skipTeamUpdate: false,
    secureLink: false,
    bankDetails: false,
    amLforAllOfficers: false,
    firstResponse: false,
    accountManagar: false,
    accountant: false,
    mode: false,
    agentCredentialsRemark: false,
    clientBrand: false,
    clientProposition: false,
    S455TaxLiability: false,
    mtsAutomationSettings: {
      automate: false,
      days: 0,
    },
    soaNotificationSettings: {
      day: 1,
      frequency: 3,
      amount: 100,
      notifyOnLastDay: false,
    },
    noReply: {
      enabled: false,
      replyEmail: "",
    },
    reviewRequestConfig: {
      APCT: false,
      SA100: false,
      SA800: false,
      VAT: false,
    },
  },
  bankAccount: {
    accountName: "",
    accountNumber: "",
    bankName: "",
    bik: "",
    iban: "",
    shortCode: "",
  },
  website: {
    iconUrl: "",
    logoUrl: "",
    url: "",
    urlPrivacy: "",
    urlTnC: "",
  },
  name: "",
  email: "",
  phoneNumber: 0,
  timeZone: "",
  vat: {
    enabled: false,
    rate: 0,
  },
  emailProvider: 0,
  defaultBCCs: [],
  defaultCCs: [],
  sequences: {
    invoice: {
      leadingZero: 0,
      postfix: "",
      prefix: "",
      seperator: "",
      type: 0,
    },
    quote: {
      leadingZero: 0,
      postfix: "",
      prefix: "",
      seperator: "",
      type: 0,
    },
  },
  badges: {
    warningMessage: "",
    dateFrom: "",
    dateTo: "",
    sA100: false,
    sA800: false,
    cis: false,
    vat: false,
    eps: false,
    fps: false,
    confirmationStatement: false,
    corporationTax: false,
    accountsReport: false,
  },
};

const PracticeDetails = ({ data }: any) => {
  const { openNotification } = React.useContext(adminContext);
  const navigate = useNavigate();
  const [form] = useForm();
  const { id } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [practicesData, setPracticesData] = React.useState<IPractice>(defaultData);
  const [isEdit, setIsEdit] = React.useState<{ type: PracticeUpdateTypes }>({type: PracticeUpdateTypes.none});
  const [inactive, setInactive] = React.useState(false);

  console.log('>>>>>>>',data)

  React.useEffect(() => {
    setLoading(true);
    if (id) {
      PracticesService.getPracticeDetails(id)
        .then((res) => {
          if (res.status) {
            setPracticesData(res.result);
            form.setFieldsValue(res.result); // Populate form with practice details
            setInactive(res.result?.inActive || false);
          } else {
            openNotification("error", "Failed to fetch practice details.");
          }
        })
        .catch((err) => {
          openNotification(
            "error",
            err || "An error occurred while fetching details."
          );
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const OnCancelClick = () => {
    setIsEdit({ type: PracticeUpdateTypes.none });
    SetFormValues(practicesData);
  };

  const OnSaveClick = async () => {
    setLoading(true);
    // Validate fields and preprocess the dateRange field
    const values = await form.validateFields();
    const badges = values.badges || {};

    // Transform dateRange into dateFrom and dateTo
    if (badges.dateRange && badges.dateRange.length === 2) {
      badges.dateFrom = badges.dateRange[0]?.format("YYYY-MM-DD");
      badges.dateTo = badges.dateRange[1]?.format("YYYY-MM-DD");
      delete badges.dateRange; // Remove the original dateRange field
    }

    // Prepare data to send to the API
    const payload = { ...values, badges };
    const type = isEdit.type;

    PracticesService.updatePracticeDetails(id, type, payload)
      .then((res) => {
        if (res.status) {
          setIsEdit({ type: PracticeUpdateTypes.none });
          setPracticesData(res?.result);
          openNotification("success", "Practice details updated successfully");
        } else if (res.errors) {
          openNotification("error", res?.errors);
        }
      })
      .catch((error) => {
        openNotification("error", error || "Validate Fields");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleValuesChange = async (changedValues: any, allValues: any) => {
    var vals = await form.validateFields().then((values) => {
      return values;
    });
    setPracticesData(vals);
  };
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Basic Information",
      children: (
        <>
          <BasicInformation
            form={form}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            OnSaveClick={OnSaveClick}
            OnCancelClick={OnCancelClick}
            handleValuesChange={handleValuesChange}
            isInactive={inactive}
          />
          <BankAccount
            form={form}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            OnSaveClick={OnSaveClick}
            OnCancelClick={OnCancelClick}
            handleValuesChange={handleValuesChange}
            isInactive={inactive}
          />
        </>
      ),
    },
    {
      key: "2",
      label: "TimeZone, VAT & Email",
      children: (
        <TimeZoneVATAndEmail
          form={form}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          OnSaveClick={OnSaveClick}
          OnCancelClick={OnCancelClick}
          handleValuesChange={handleValuesChange}
          isInactive={inactive}
        />
      ),
    },
    {
      key: "3",
      label: "Sequence & Review",
      children: (
        <>
          <PracticeSequence
            form={form}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            OnSaveClick={OnSaveClick}
            OnCancelClick={OnCancelClick}
            handleValuesChange={handleValuesChange}
            isInactive={inactive}
          />
          <ReviewRequest
            form={form}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            OnSaveClick={OnSaveClick}
            OnCancelClick={OnCancelClick}
            handleValuesChange={handleValuesChange}
            isInactive={inactive}
          />
        </>
      ),
    },
    {
      key: "4",
      label: "Features",
      children: (
        <Features
          form={form}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          OnSaveClick={OnSaveClick}
          OnCancelClick={OnCancelClick}
          handleValuesChange={handleValuesChange}
          isInactive={inactive}
        />
      ),
    },
    {
      key: "5",
      label: "Important Badges",
      children: (
        <ImportantBadges
          form={form}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          OnSaveClick={OnSaveClick}
          OnCancelClick={OnCancelClick}
          handleValuesChange={handleValuesChange}
          isInactive={inactive}
        />
      ),
    },
  ];
  const SetFormValues = (currValues: any) => {
    form.setFieldsValue({
      address: currValues?.address,
      params: currValues?.params,
      appFeature: currValues?.appFeature,
      appSubFeature: {
        ...currValues?.appSubFeature,
        mtsAutomationSettings: currValues?.appSubFeature?.mtsAutomationSettings,
        soaNotificationSettings:
          currValues?.appSubFeature?.soaNotificationSettings,
        noReply: currValues?.appSubFeature?.noReply,
        reviewRequestConfig: currValues?.appSubFeature?.reviewRequestConfig,
      },
      bankAccount: currValues?.bankAccount,
      website: currValues?.website,
      name: currValues?.name,
      email: currValues?.email,
      phoneNumber: currValues?.phoneNumber,
      timeZone: currValues?.timeZone,
      vat: currValues?.vat,
      emailProvider: currValues?.emailProvider,
      defaultBCCs: currValues?.defaultBCCs,
      defaultCCs: currValues?.defaultCCs,
      sequences: {
        invoice: currValues?.sequences?.invoice,
        quote: currValues?.sequences?.quote,
      },
      badges: {
        ...currValues?.badges,
        dateRange: [
          currValues?.badges?.dateFrom
            ? dayjs(currValues.badges.dateFrom)
            : null,
          currValues?.badges?.dateTo ? dayjs(currValues.badges.dateTo) : null,
        ],
      },
    });
  };

  return (
    <Spin
      spinning={loading}
      indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
    >
      <Row justify="start" align="middle" style={{ marginBottom: "6px" }}>
        {/* <Breadcrumb
        separator=">"
        items={[
          {
            title: 'Practices',
            href:'/practices'
          },
          {
            title: `${practicesData?.name}`,
          },
        ]}
      /> */}
        <Breadcrumb style={{ color: "#172B4D" }}>
          <Breadcrumb.Item onClick={() => navigate("/practices")}>
            Practices
          </Breadcrumb.Item>
          <Breadcrumb.Item> {practicesData?.name}</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row
        className="roundedCornerSmall p-1"
        style={{ backgroundColor: "white", padding: "10px 20px " }}
      >
        <Col span={24} style={{ minHeight: window.innerHeight - 200 }}>
          <Row>
            <Col
              span={23}
              style={{ display: "flex", alignItems: "center", padding: 10 }}
            >
              <Image
                className="rounded"
                style={{ height: "44px", width: "auto" }}
                src={
                  practicesData?.website?.logoUrl !== undefined &&
                  practicesData?.website?.logoUrl !== "" &&
                  practicesData?.website?.logoUrl !== null
                    ? `${practicesData?.website?.logoUrl}`
                    : NoImageToShow
                }
                alt="Company logo"
              />
              <Col style={{ paddingLeft: "20px" }}>
                <Col id="practicetname" style={{ fontSize: 25 }}>
                  {practicesData?.name}
                </Col>
                <a href={`mailto: ${practicesData?.email}`}>
                  {practicesData?.email}
                </a>
              </Col>
            </Col>
            <Col span={23}>
              <Tabs
                defaultActiveKey="1"
                size="small"
                items={items}
                onChange={() => {}}
              />
            </Col>
          </Row>
          {/* <Row className="h100" justify="center" align="middle">
            <Image src={notFound} preview={false} />
          </Row> */}
        </Col>
      </Row>
    </Spin>
  );
};

export default PracticeDetails;
