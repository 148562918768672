import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Col, Input, Row, Spin } from "antd";
import Table, { ColumnsType } from "antd/es/table";

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import PayrollYearlySettingsService from "../../Services/PayrollYearlySettingsService";
import { adminContext } from "../../Common/PageRoute";
import dayjs from "dayjs";
import AddPayrollYearlySettings from "./AddPayrollYearlySettings";

const { Search } = Input;
interface ListDataType {
  key: React.Key;
  id: number;
  yearId: number;
  defaultTaxCode: string;
  statePensionAge: string;
  sspWkPay: number;
  sapawePay: number;
  smpawePay: number;
  ppBirthAWEPay: number;
  ppAdoptionAWEPay: number;
  eaLimit: number;
  awe: number;
  pensionLowerLimit: number;
  pensionUpperLimit: number;
  taTaxableFrom: number;
  createdBy: any;
}
interface ListData {
  items: ListDataType[];
  totalItems: number;
}
const PayrollYearlySettings = () => {
  const { openNotification } = React.useContext(adminContext);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [dialog, setDialog] = React.useState({ type: "", params: {} });
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [selectedYearlySettings, setSelectedYearlySettings] =
    React.useState<ListDataType>();

  const [payrollYearlySettings, setPayrollYearlySettings] =
    React.useState<ListData>();

  const [pagingItems, setPagingItems] = React.useState({
    totalRecords: 0,
    currentPage: 1,
    pageSize: 15,
  });
  const [listParams, setListParams] = React.useState({
    search: "",
    sortDir: "ascend",
    sortCol: "YearId",
  });
  const [refresh, setRefresh] = React.useState(false);
  const columns: ColumnsType<any> = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "Sno",
      width: "3%",
      render: (_: any, record: any, index: any) => <p>{index + 1}</p>,
    },
    {
      title: "Year ID",
      dataIndex: "yearId",
      key: "YearId",
      sorter: true,
      width: "6%",
      render: (text: any, record: any) => (
        <Link title={text} to={`/payrollyearlysettings/${record.id}`}>
          {`${1999 + text}-${text}`}
        </Link>
      ),
    },
    {
      title: "Tax Code",
      dataIndex: "defaultTaxCode",
      key: "DefaultTaxCode",
      width: "5%",
    },
    {
      title: "State PA",
      dataIndex: "statePensionAge",
      key: "StatePensionAge",
      width: "5%",
    },
    {
      title: "Pension LL",
      dataIndex: "pensionLowerLimit",
      key: "PensionLowerLimit",
      width: "6%",
    },
    {
      title: "Pension UL",
      dataIndex: "pensionUpperLimit",
      key: "PensionUpperLimit",
      width: "6%",
    },
    {
      title: "EA Limit",
      dataIndex: "eaLimit",
      key: "EALimit",
      width: "5%",
    },
    {
      title: "AWE",
      dataIndex: "awe",
      key: "AWE",
      width: "4%",
    },
    {
      title: "SSP WP",
      dataIndex: "sspWkPay",
      key: "SSPWKPay",
      width: "5%",
    },
    {
      title: "SAP AWE Pay",
      dataIndex: "sapawePay",
      key: "SAPAWEPay",
      width: "7%",
    },
    {
      title: "SMP AWE Pay",
      dataIndex: "smpawePay",
      key: "SMPAWEPay",
      width: "7%",
    },
    {
      title: "PP Birth AWE Pay",
      dataIndex: "ppBirthAWEPay",
      key: "PPBirthAWEPay",
      width: "9%",
    },
    {
      title: "PP Adoption AWE Pay",
      dataIndex: "ppAdoptionAWEPay",
      key: "PPAdoptionAWEPay",
      width: "10%",
    },
    {
      title: "TA Taxable Form",
      dataIndex: "taTaxableFrom",
      key: "TATaxableFrom",
      width: "9%",
    },
    {
      title: "Created By",
      dataIndex: "createdBy.date",
      key: "CreatedBy.date",
      render: (text: any, record: any) => (
        <span>{dayjs(record?.createdBy?.date).format("DD-MM-YYYY")}</span>
      ),
    },
  ];

  const getItems = async (start: any, length: any) => {
    let res = await PayrollYearlySettingsService.getPayrollYearlySettingsList(
      start,
      length,
      listParams.search,
      listParams.sortCol,
      listParams.sortDir
    );
    if (res?.status) {
      setLoading(false);
      var data: ListDataType[] = res?.result?.items.map((item: any) => {
        var itemWithKey = {
          key: item?.id,
          id: item?.id,
          yearId: item?.yearId,
          defaultTaxCode: item?.defaultTaxCode,
          statePensionAge: item?.statePensionAge,
          sspWkPay: item?.sspWkPay,
          sapawePay: item?.sapawePay,
          smpawePay: item?.smpawePay,
          ppBirthAWEPay: item?.ppBirthAWEPay,
          ppAdoptionAWEPay: item?.ppAdoptionAWEPay,
          eaLimit: item?.eaLimit,
          awe: item?.awe,
          pensionLowerLimit: item?.pensionLowerLimit,
          pensionUpperLimit: item?.pensionUpperLimit,
          taTaxableFrom: item?.taTaxableFrom,
          createdBy: item?.createdBy,
        };
        return itemWithKey;
      });
      setPayrollYearlySettings({
        totalItems: res?.result?.items?.length,
        items: data,
      });
    } else {
      openNotification("error", res.message);
      setLoading(false);
    }
  };
  React.useEffect(() => {
    //getItems();
    setLoading(true);
    getItems(
      (pagingItems.currentPage - 1) * pagingItems.pageSize,
      pagingItems.pageSize
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listParams, refresh]);

  return (
    <>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Row justify="start" align="middle" style={{ marginBottom: "6px" }}>
          <Breadcrumb style={{ color: "#172B4D" }}>
            <Breadcrumb.Item onClick={() => navigate("/payrollyearlysettings")}>
              Payroll Yearly Settings
            </Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <Row
          className="roundedCornerSmall p-3"
          style={{ backgroundColor: "white" }}
        >
          <Col span={24}>
            <Row justify="space-between" className="pb-3">
              <Button
                type="primary"
                className="add-btn"
                onClick={() => {
                  setDialog({ type: "addYearlySettings", params: {} });
                }}
              >
                <PlusOutlined />
                {selectedRowKeys.length === 1
                  ? "Clone Payroll Yearly Settings"
                  : "Add Payroll Yearly Settings"}
              </Button>
              <Search
                size="small"
                placeholder="Search..."
                className="att-search-input mb-1 me-5"
                onSearch={(val: string) =>
                  setListParams({ ...listParams, search: val })
                }
                onChange={(e) =>
                  e.target.value === ""
                    ? setListParams({ ...listParams, search: "" })
                    : null
                }
                style={{ width: 170 }}
              />
            </Row>
          </Col>
          <Col span={24} style={{ minHeight: window.innerHeight - 200 }}>
            <>
              <Table
                rowSelection={{
                  type: "checkbox",
                  selectedRowKeys: selectedRowKeys,
                  onSelect: (record: ListDataType) => {
                    let selItems: React.Key[] = [];
                    if (!selectedRowKeys.includes(record?.id)) {
                      selItems.push(record?.id);
                    }
                    setSelectedRowKeys(selItems);
                    setSelectedYearlySettings(record);
                  },
                }}
                // rowClassName={(record:any) => record?.id !== selectedRowKeys  ? "disabled-row" : ""}
                dataSource={payrollYearlySettings?.items}
                columns={columns}
                size="small"
                className="aoa-custom-table"
                scroll={{ x: 700 }}
                pagination={{
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                  current: pagingItems.currentPage,
                  pageSize: pagingItems.pageSize,
                  showSizeChanger: true,
                  total: pagingItems.totalRecords,
                  pageSizeOptions: ["15", "25", "50", "100"],
                }}
                onChange={(paging, filter, sort: any) => {
                  sort.order !== undefined &&
                    setListParams({
                      ...listParams,
                      sortDir: sort.order,
                      sortCol: sort.columnKey,
                    });
                  if (
                    paging?.current !== undefined &&
                    paging?.pageSize !== undefined
                  ) {
                    setLoading(true);
                    setPagingItems((p) => {
                      p.currentPage =
                        paging.current !== undefined ? paging.current : 1;
                      p.pageSize =
                        paging.pageSize !== undefined ? paging.pageSize : 15;
                      return p;
                    });
                    getItems(
                      (paging.current - 1) * paging.pageSize,
                      paging.pageSize
                    );
                  }
                }}
              />
            </>
          </Col>
        </Row>
        {dialog.type === "addYearlySettings" && (
          <AddPayrollYearlySettings
            id={selectedRowKeys[0]}
            record={selectedYearlySettings}
            onDismiss={(conformation: boolean) => {
              setDialog({ type: "", params: {} });
              if (conformation) {
                setRefresh((x) => x = !x);
              }
            }}
          />
        )}
      </Spin>
    </>
  );
};

export default PayrollYearlySettings;
