import { UserOutlined } from "@ant-design/icons";
import { Card, Col, Input, Modal, Row, Space, Statistic } from "antd";
import React from "react";

const PracticeCard = ({userData,loading}:{userData:[], loading:any}) => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [selectedCard, setSelectedCard] = React.useState<any>(null);

  const handleCardClick = (id: string) => {
    const cardDetails = userData.find((item: any) => item.id === id); // Find the card details
    setSelectedCard(cardDetails);
    setShowModal(true);
  };

  return (
    <>
      <Row style={{ marginBottom: 5, padding: 20 }}>
        <Space size={[8, 16]} wrap>
          {userData.map((item: any, index) => (
            <Card
              loading={loading}
              key={index}
              hoverable
              bordered={false}
              style={{ cursor: "pointer",width:200 }}
              onClick={() => handleCardClick(item.id)}
            >
              <Statistic
                title={<div title={item?.name} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item?.name}</div>}
                value={item?.totalClients}
                valueStyle={{ color: "#3f8600", fontSize: "medium"}}
                prefix={<UserOutlined title={`Practice ${item?.id} have ${item?.activeUsersCount} active users`} />}
              />
            </Card>
          ))}
        </Space>
      </Row>

      <Modal
        title={`Practice Details`}
        width={500}
        loading={loading}
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        {selectedCard ? (
          <>
          <Row gutter={[16, 16]} style={{padding:10}}>
            <Col span={8}>
              <label >Name</label>
            </Col>
            <Col span={16} >
              <Input size="small"  disabled value={selectedCard?.name} />
            </Col>
            <Col span={8}>
              <label >Practice</label>
            </Col>
            <Col span={16} >
              <Input size="small"  disabled value={selectedCard?.id}/>
            </Col>

            <Col span={8}>
              <label >Active Users</label>
            </Col>
            <Col span={16} >
              <Input size="small"  disabled value={selectedCard?.activeUsersCount}/>
            </Col>

            <Col span={8}>
              <label >Email</label>
            </Col>
            <Col span={16} >
              <Input size="small"  disabled value={selectedCard?.email}/>
            </Col>
            <Col span={8}>
              <label >Mobile No</label>
            </Col>
            <Col span={16} >
              <Input size="small"  disabled value={selectedCard?.phoneNumber}/>
            </Col>

            <Col span={8}>
              <label >Active Clients</label>
            </Col>
            <Col span={16} >
              <Input size="small"  disabled value={selectedCard?.totalClients}/>
            </Col>

        </Row>
          </>
        ) : (
          <p>No details available.</p>
        )}
      </Modal>
    </>
  );
};

export default PracticeCard;
