import { Checkbox, Col, Divider, Form, Modal, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import PracticesService from "../../Services/PracticesService";
import { adminContext } from "../../Common/PageRoute";

const ViewPracticeDetails = ({ record, onDismiss }: any) => {
  const [form] = useForm();
  const [loading, setLoading] = React.useState(false);
  const { openNotification } = React.useContext(adminContext);
  const [practiceFeatures, setPracticeData] = React.useState<any>({});

  React.useEffect(() => {
    const getFeatures = async () => {
      setLoading(true);
      let res = await PracticesService.getFeaturePracticeDetails(record?.id);
      if (res?.status) {
        setPracticeData(res?.result);
        form.setFieldsValue({
          appFeature: res?.result?.appFeature,
          appSubFeature: res?.result?.appSubFeature,
        });
        setLoading(false);
      } else {
        openNotification("error", "Detail not found");
        setLoading(false);
      }
    };
    if (record?.id) getFeatures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record?.id]);
  return (
    <Modal
      confirmLoading={loading}
      title={`View Features for ${practiceFeatures?.name}`}
      className="practice-view-details"
      width={800}
      open={true}
      onCancel={() => onDismiss(false)}
      footer={[]}
    >
      <Form form={form} style={{ pointerEvents: "none" }}>
      <Divider orientationMargin={0} orientation="left" >Features</Divider>     
        <Row wrap gutter={[0,4]} className="px-3">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "calls"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Calls
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "leadSources"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Lead Sources
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "linkedinLeads"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                LinkedIn Leads
              </Checkbox>
            </Form.Item>
          </Col>
        
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "webForms"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Web Forms
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "dayEnd"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Days End
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "accounts"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Accounts
              </Checkbox>
            </Form.Item>
          </Col>
       
        
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "corporationTax"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Corporation tax
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "aml"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                AML
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "chats"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Chats
              </Checkbox>
            </Form.Item>
          </Col>
        
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "teams"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Teams
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "bankStatement"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Bank Statements
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "payroll"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Payroll
              </Checkbox>
            </Form.Item>
          </Col>
        
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "clientDataRequest"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Client Data Qequest
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "bookkeeping"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Bookkeeping
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "timeSheet"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                TimeSheet
              </Checkbox>
            </Form.Item>
          </Col>
        
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "careOf"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                CareOf
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "selfAssessment"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Self Assessement
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "dms"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                DMS
              </Checkbox>
            </Form.Item>
          </Col>
      
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "other4"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Other4
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "other5"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Other5
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Divider orientationMargin={0} orientation="left">Sub Features</Divider>
        <Row wrap gutter={[0,4]} className="px-3">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "digitalSignatureWithoutAML"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Digital Signature Without AML
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "websiteQueriesWithOTP"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Website Quries With OTP
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "addCustomerWithoutLead"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Add Customer Without Lead
              </Checkbox>
            </Form.Item>
          </Col>
          {form.getFieldValue(["appFeature", "timeSheet"]) && (
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "manualTimesheet"]}
                valuePropName="checked"
              >
                <Checkbox disabled={false} >
                  Manual TimeSheet
                </Checkbox>
              </Form.Item>
            </Col>
          )}
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "progressiveLeads"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Progressive Leads
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "witoutDiscount"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Without Discount
              </Checkbox>
            </Form.Item>
          </Col>
        
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "statementOfAccounts"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Statement of Account
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "actionTab"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} >
                Action Tab
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "scheduleEmail"]}
              valuePropName="checked"
            >
              <Checkbox >Schedule Email</Checkbox>
            </Form.Item>
          </Col>
       
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "resourceSection"]}
              valuePropName="checked"
            >
              <Checkbox >
                Resource section (Reports)
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "clientAutomation"]}
              valuePropName="checked"
            >
              <Checkbox >Client automation</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "proofOfEarningFPS"]}
              valuePropName="checked"
            >
              <Checkbox >
                Proof of earning (FPS)
              </Checkbox>
            </Form.Item>
          </Col>
        
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "receiptAttachments"]}
              valuePropName="checked"
            >
              <Checkbox >
                Receipt Attachments
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "skipTeamUpdate"]}
              valuePropName="checked"
            >
              <Checkbox >Skip team update</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "amLforAllOfficers"]}
              valuePropName="checked"
            >
              <Checkbox >
                AML for all officers
              </Checkbox>
            </Form.Item>
          </Col>
        
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "bankDetails"]}
              valuePropName="checked"
            >
              <Checkbox >Bank Details</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "allowTLandAdminToUpdateTaskDeadline"]}
              valuePropName="checked"
            >
              <Checkbox >
                Task deadline by team leaderk
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "directorAccountWhileRetrieve"]}
              valuePropName="checked"
            >
              <Checkbox >Directors accounts</Checkbox>
            </Form.Item>
          </Col>
        
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "secureLink"]}
              valuePropName="checked"
            >
              <Checkbox >
                Send attachments via secure link
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "reviewNotificationToTeamleader"]}
              valuePropName="checked"
            >
              <Checkbox >
                Review notifications to teamleaders
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "noChangeInTaskAssigneeWithTeamChange"]}
              valuePropName="checked"
            >
              <Checkbox >
                Change assignee with a change in account manager
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ViewPracticeDetails;
