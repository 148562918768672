import { CloseOutlined, EditTwoTone, SaveOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { PracticeUpdateTypes } from "../../Services/PracticesService";
import { Utils } from "../../Utilities/Utils";
import { adminContext } from "../../Common/PageRoute";

const BasicInformation = ({
  form,
  isEdit,
  setIsEdit,
  OnSaveClick,
  OnCancelClick,
  handleValuesChange,
  isInactive,
}: any) => {
  const { countriesOptions } = React.useContext(adminContext);
  return (
    <Form requiredMark={false} form={form} onValuesChange={handleValuesChange}>
      <Col className="card border pb-3 border-rounded-1 bg-secondary">
        <Row
          className="border-bottom  px-2 py-1 font-bold"
          justify="space-between"
        >
          Basic Information
          <Button
            icon={<EditTwoTone />}
            type="text"
            disabled={isInactive}
            style={{ border: "none" }}
            onClick={() =>
              setIsEdit({ type: PracticeUpdateTypes.basicinformation })
            }
          />
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
              label="Name"
              name="name"
              rules={[
                { required: true, message: "Name is requried" },
                { validator: Utils.validateWhiteSpace },
              ]}
            >
              <Input
                disabled={
                  isEdit.type === PracticeUpdateTypes.basicinformation
                    ? false
                    : true
                }
                onKeyPress={(e: any) => Utils.ValidateCharOnly(e)}
                onInput={(e: any) =>
                  (e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase())
                }
                className="line-height-28"
                placeholder="Name"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="Email"
              name="email"
              rules={[
                { required: true, message: "This field is required" },
                { validator: Utils.ValidateEmailFormat },
              ]}
            >
              <Input
                disabled={
                  isEdit.type === PracticeUpdateTypes.basicinformation
                    ? false
                    : true
                }
                className="line-height-28"
                placeholder="Email"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 6 }}
              label="Phone Number"
              name="phoneNumber"
            >
              <Input
                disabled={
                  isEdit.type === PracticeUpdateTypes.basicinformation
                    ? false
                    : true
                }
                onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                maxLength={11}
                className="line-height-28"
                placeholder="Phone Number"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3">
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
              label="Url"
              name={["website", "url"]}
            >
              <Input
                disabled={
                  isEdit.type === PracticeUpdateTypes.basicinformation
                    ? false
                    : true
                }
                className="line-height-28"
                placeholder="Url"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="Url T&C"
              name={["website", "urlTnC"]}
            >
              <Input
                disabled={
                  isEdit.type === PracticeUpdateTypes.basicinformation
                    ? false
                    : true
                }
                className="line-height-28"
                placeholder="Url T&C"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="Url Privacy"
              name={["website", "urlPrivacy"]}
            >
              <Input
                disabled={
                  isEdit.type === PracticeUpdateTypes.basicinformation
                    ? false
                    : true
                }
                className="line-height-28"
                placeholder="Url Privacy"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3">
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
              label="Icon Url"
              name={["website", "iconUrl"]}
            >
              <Input
                disabled={
                  isEdit.type === PracticeUpdateTypes.basicinformation
                    ? false
                    : true
                }
                className="line-height-28"
                placeholder="Icon Url"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="Logo Url"
              name={["website", "logoUrl"]}
            >
              <Input
                disabled={
                  isEdit.type === PracticeUpdateTypes.basicinformation
                    ? false
                    : true
                }
                className="line-height-28"
                placeholder="Logo Url"
              />
            </Form.Item>
          </Col>
        </Row>
        {isEdit.type === PracticeUpdateTypes.basicinformation && (
          <Row className="px-3 pt-3">
            <Button type="primary" onClick={OnSaveClick}>
              <SaveOutlined /> Save
            </Button>
            <Button className="ms-2" onClick={OnCancelClick}>
              <CloseOutlined /> Cancel
            </Button>
          </Row>
        )}
      </Col>
      <Col className="card border pb-3 border-rounded-1 bg-secondary my-4">
        <Row
          className="border-bottom  px-2 py-1 font-bold"
          justify="space-between"
        >
          Address Details
          <Button
            icon={<EditTwoTone />}
            type="text"
            disabled={isInactive}
            style={{ border: "none" }}
            onClick={() => setIsEdit({ type: PracticeUpdateTypes.address })}
          />
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
              label="Building"
              name={["address", "building"]}
            >
              <Input
                disabled={
                  isEdit.type === PracticeUpdateTypes.address ? false : true
                }
                className="line-height-28"
                placeholder="Building"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
              label="Street"
              name={["address", "street"]}
            >
              <Input
                disabled={
                  isEdit.type === PracticeUpdateTypes.address ? false : true
                }
                className="line-height-28"
                placeholder="Street"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
              label="City / Town"
              name={["address", "city"]}
            >
              <Input
                disabled={
                  isEdit.type === PracticeUpdateTypes.address ? false : true
                }
                className="line-height-28"
                placeholder="City / Town"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 ">
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
              label="County"
              name={["address", "county"]}
            >
              <Input
                disabled={
                  isEdit.type === PracticeUpdateTypes.address ? false : true
                }
                onKeyPress={(e: any) => Utils.ValidateCharOnly(e)}
                className="line-height-28"
                placeholder="County"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
              label="Post Code"
              name={["address", "postcode"]}
            >
              <Input
                disabled={
                  isEdit.type === PracticeUpdateTypes.address ? false : true
                }
                onKeyPress={(e: any) => Utils.ValidateCharAndNumberOnly(e)}
                className="line-height-28"
                placeholder="Post Code"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 8 }}
              label="Country"
              name={["address", "country"]}
            >
              <Select
                disabled={
                  isEdit.type === PracticeUpdateTypes.address ? false : true
                }
                className="line-height-28"
                placeholder="Country"
                options={countriesOptions}
              />
            </Form.Item>
          </Col>
        </Row>
        {isEdit.type === PracticeUpdateTypes.address && (
          <Row className="px-3 pt-3">
            <Button type="primary" onClick={OnSaveClick}>
              <SaveOutlined /> Save
            </Button>
            <Button className="ms-2" onClick={OnCancelClick}>
              <CloseOutlined /> Cancel
            </Button>
          </Row>
        )}
      </Col>
    </Form>
  );
};

export default BasicInformation;
