import { ApiUtility } from "../Utilities/ApiUtility.axios";

export enum ReleaseUpdateTypes
{
    Unknown = 0,
    Enhancement = 1,
    New = 2,
    Fix = 3
}
class UserService{
    route = `/Api/User`;

    getUsers = (start:number, length:any, search:string, sortCol:any, sortDir:any) =>  ApiUtility.get(`${this.route}`, {start, length, search, sortDir, sortCol})

    getUserDetails = (id:string) => ApiUtility.get(`${this.route}/${id}`);

    updateUserDetails = (id:string, params:any) => ApiUtility.post(`${this.route}/Profile/${id}`, params);

    createNewUser = (params:any) => ApiUtility.post(`${this.route}`, params);

    getUserDetailsforChart = () => ApiUtility.get(`${this.route}/ChartView`);
}
export default new UserService();