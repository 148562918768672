import { CloseOutlined, EditTwoTone, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  SelectProps,
} from "antd";
import { PracticeUpdateTypes } from "../../Services/PracticesService";
import { Utils } from "../../Utilities/Utils";
import { getTimeZonesOptions } from "../../Common/CommonComponent";
const TimeZoneVATAndEmail = ({
  form,
  isEdit,
  setIsEdit,
  OnSaveClick,
  OnCancelClick,
  handleValuesChange,
  isInactive,
}: any) => {
  const BCCoptions: SelectProps["options"] = [];
  const CCoptions: SelectProps["options"] = [];
  // const timeZoneOptions: SelectProps['options'] = [];
  var bCCs = form.getFieldValue("defaultBCCs");
  var CCs = form.getFieldValue("defaultCCs");

  bCCs?.forEach((item: any) => {
    BCCoptions.push({
      label: item,
      value: item,
    });
  });
  CCs?.forEach((item: any) => {
    CCoptions.push({
      label: item,
      value: item,
    });
  });

  return (
    <Form form={form} onValuesChange={handleValuesChange}>
      <Col className="card border pb-3 border-rounded-1 bg-secondary">
        <Row
          className="border-bottom  px-2 py-1 font-bold"
          justify="space-between"
        >
          TimeZone, VAT
          <Button
            icon={<EditTwoTone />}
            type="text"
            disabled={isInactive}
            style={{ border: "none" }}
            onClick={() => setIsEdit({ type: PracticeUpdateTypes.timezonevat })}
          />
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 10 }}
              label="TimeZone"
              name="timeZone"
            >
              <Select
                disabled={
                  isEdit.type === PracticeUpdateTypes.timezonevat ? false : true
                }
                className="line-height-28"
                placeholder="TimeZone"
                options={getTimeZonesOptions()}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ display: "flex", alignItems: "center" }}>
            <Form.Item
              className="custom-form-item"
              valuePropName="checked"
              name={["vat", "enabled"]}
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.timezonevat ? false : true
                }
                className="line-height-28"
              >
                VAT Paye
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 8 }}
              label="VAT Rate"
              name={["vat", "rate"]}
            >
              <Input
                disabled={
                  isEdit.type === PracticeUpdateTypes.timezonevat ? false : true
                }
                onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                className="line-height-28"
                placeholder="VAT Rate"
              />
            </Form.Item>
          </Col>
        </Row>
        {isEdit.type === PracticeUpdateTypes.timezonevat && (
          <Row className="px-3 pt-3">
            <Button type="primary" onClick={OnSaveClick}>
              <SaveOutlined /> Save
            </Button>
            <Button className="ms-2" onClick={OnCancelClick}>
              <CloseOutlined /> Cancel
            </Button>
          </Row>
        )}
      </Col>
      <Col className="card border pb-3 border-rounded-1 bg-secondary my-4">
        <Row
          className="border-bottom  px-2 py-1 font-bold"
          justify="space-between"
        >
          Emails
          <Button
            icon={<EditTwoTone />}
            type="text"
            disabled={isInactive}
            style={{ border: "none" }}
            onClick={() => setIsEdit({ type: PracticeUpdateTypes.emails })}
          />
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8} style={{ display: "flex", alignItems: "center" }}>
            <Form.Item
              style={{ width: "100%" }}
              className="custom-form-item"
              name="emailProvider"
            >
              <Radio.Group
                style={{ display: "flex", justifyContent: "space-evenly" }}
                disabled={
                  isEdit.type === PracticeUpdateTypes.emails ? false : true
                }
              >
                <Radio
                  value={0}
                  className="border  border-rounded-1 mail-radio-btn"
                >
                  <span className="mail-radio-btn-span">
                    <img
                      style={{ height: 20, width: 20 }}
                      alt="email"
                      src="https://cdn-icons-png.flaticon.com/512/5968/5968534.png"
                    />
                    <div>Gmail</div>
                  </span>
                </Radio>
                <Radio
                  value={1}
                  className="border  border-rounded-1 mail-radio-btn"
                >
                  <span className="mail-radio-btn-span">
                    <img
                      style={{ height: 20, width: 20 }}
                      alt="email"
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/df/Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg/800px-Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg.png"
                    />
                    <div>Outlook</div>
                  </span>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
              label="BCCs"
              name="defaultBCCs"
            >
              <Select
                mode="multiple"
                className="line-height-28"
                disabled={
                  isEdit.type === PracticeUpdateTypes.emails ? false : true
                }
                placeholder="BCCs"
                options={BCCoptions}
              />
            </Form.Item>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
              label="CCs"
              name="defaultCCs"
            >
              <Select
                mode="multiple"
                // defaultValue={["a0000", "c111"]}
                className="line-height-28"
                disabled={
                  isEdit.type === PracticeUpdateTypes.emails ? false : true
                }
                placeholder="CCs"
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        {isEdit.type === PracticeUpdateTypes.emails && (
          <Row className="px-3 pt-3">
            <Button type="primary" onClick={OnSaveClick}>
              <SaveOutlined /> Save
            </Button>
            <Button className="ms-2" onClick={OnCancelClick}>
              <CloseOutlined /> Cancel
            </Button>
          </Row>
        )}
      </Col>
    </Form>
  );
};

export default TimeZoneVATAndEmail;
