import React from "react";
import { Empty, Flex, Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import PracticesService from "../../Services/PracticesService";
import EChartsReact from "echarts-for-react";

const SubmissionChart = ({ practiceIds }: { practiceIds: any }) => {
  const [chartOptions, setChartOptions] = React.useState<any>({xData: [],yData: [] });
  const [loader, setLoader] = React.useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = React.useState<any>();

  const practiceOptions = practiceIds?.map((ids: any) => ({
    label: `Practice ${ids}`,
    value: ids,
  }));

  React.useEffect(() => {
    setSelectedFilter(practiceIds[0]);
  }, [practiceIds]);

  // Fetch data when the selected filter (practiceId) changes
  React.useEffect(() => {
    if (!selectedFilter) {
        setChartOptions({ xData: [], yData: [] }); // Reset chart data when no selection
        return;
      }
    setLoader(true);
    PracticesService.getSubmisionData(selectedFilter)
      .then((response) => {
        if (response.status && response.result) {
          const xData = Object.keys(response.result);
          const yData = Object.values(response.result);
          for (var i = 0; i < xData.length; i++) {
            xData[i] = xData[i].toUpperCase();
          }
          setChartOptions({ xData, yData }); // Update chart data
        } else {
          setChartOptions({ xData: [], yData: [] });
        }
      })
      .catch((error) => {
        console.error("Error fetching chart data:", error);
        setChartOptions({ xData: [], yData: [] });
      })
      .finally(() => {
        setLoader(false);
      });
  }, [selectedFilter]);

  return (
    <div style={{ paddingLeft: "20px", paddingTop: "10px" }}>
      <Flex gap="middle" align="end">
        <h4
          style={{
            marginTop: "0px",
            marginBottom: "6px",
            fontSize: "16px",
            color: "#3F5BD3",
          }}
        >
          Select Practice to view total submissions
        </h4>

        <Select
          style={{ width: 200 }}
          value={selectedFilter}
          defaultValue={{ label: "Select", value: "" }}
          options={[{ label: "Select", value: "" }, ...practiceOptions]}
          onChange={(value: any) => {
            setSelectedFilter(value);
          }}
        />
      </Flex>
      <Spin
        spinning={loader}
        indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
      >
        {chartOptions.xData.length > 0 && chartOptions.yData.length > 0 ? (
          <EChartsReact
            option={{
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "shadow",
                },
              },
              grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true,
              },
              xAxis: [
                {
                  type: "category",
                  data: chartOptions.xData, // Use xData extracted from the response
                  axisTick: {
                    alignWithLabel: true,
                  },
                  axisLabel: { interval: 0, rotate: 35 },
                },
              ],
              yAxis: [
                {
                  type: "value",
                },
              ],
              series: [
                {
                  name: `Total Submissions`,
                  type: "bar",
                  barWidth: "20%",
                  data: chartOptions?.yData, // Use yData extracted from the response
                },
              ],
            }}
          />
        ) : (
          <Empty description="No Data Available" />
        )}
      </Spin>
    </div>
  );
};

export default SubmissionChart;
