import React from 'react'
import { CloseOutlined, EditTwoTone, SaveOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { PracticeUpdateTypes } from "../../Services/PracticesService";
import { Utils } from "../../Utilities/Utils";
import { adminContext } from "../../Common/PageRoute";

const BankAccount = ({
    form,
    isEdit,
    setIsEdit,
    OnSaveClick,
    OnCancelClick,
    handleValuesChange,
    isInactive,
  }: any) => {
  return (
    <Form form={form} onValuesChange={handleValuesChange}>
    <Col className="card border pb-3 border-rounded-1 bg-secondary">
      <Row
        className="border-bottom  px-2 py-1 font-bold"
        justify="space-between"
      >
        Bank Account
        <Button         
          icon={<EditTwoTone />}
          type="text"
          disabled={isInactive}
          style={{border:'none'}}
          onClick={() =>
            setIsEdit({ type: PracticeUpdateTypes.bankaccountdetails })
          }
        />
      </Row>
      <Row className="px-3 pt-1">
        <Col span={8}>
          <Form.Item
            className="custom-form-item"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 12 }}
            label="Account Name"
            name={["bankAccount", "accountName"]}
          >
            <Input
              disabled={
                isEdit.type === PracticeUpdateTypes.bankaccountdetails
                  ? false
                  : true
              }
              onKeyPress={(e: any) => Utils.ValidateCharOnly(e)}
              className="line-height-28"
              placeholder="Account Name"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            className="custom-form-item"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 18 }}
            label="Bank Name"
            name={["bankAccount", "bankName"]}
          >
            <Input
              disabled={
                isEdit.type === PracticeUpdateTypes.bankaccountdetails
                  ? false
                  : true
              }
              onKeyPress={(e: any) => Utils.ValidateCharOnly(e)}
              className="line-height-28"
              placeholder="Bank Name"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            className="custom-form-item"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 8 }}
            label="Sort Code"
            name={["bankAccount", "shortCode"]}
          >
            <Input
              disabled={
                isEdit.type === PracticeUpdateTypes.bankaccountdetails
                  ? false
                  : true
              }
              onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
              className="line-height-28"
              placeholder="Sort Code"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="px-3">
        <Col span={8}>
          <Form.Item
            className="custom-form-item"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 12 }}
            label="Account Number"
            name={["bankAccount", "accountNumber"]}
          >
            <Input
              disabled={
                isEdit.type === PracticeUpdateTypes.bankaccountdetails
                  ? false
                  : true
              }
              onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
              maxLength={8}
              className="line-height-28"
              placeholder="Accouint Number"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            className="custom-form-item"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 18 }}
            label="IBAN"
            name={["bankAccount", "iban"]}
          >
            <Input
              disabled={
                isEdit.type === PracticeUpdateTypes.bankaccountdetails
                  ? false
                  : true
              }
              className="line-height-28"
              placeholder="IBAN"
            />
          </Form.Item>
        </Col>
      </Row>
      {isEdit.type === PracticeUpdateTypes.bankaccountdetails && (
        <Row className="px-3 pt-3">
          <Button type="primary" onClick={OnSaveClick}>
            <SaveOutlined /> Save
          </Button>
          <Button className="ms-2" onClick={OnCancelClick}>
            <CloseOutlined /> Cancel
          </Button>
        </Row>
      )}
    </Col>
  </Form>
  )
}

export default BankAccount