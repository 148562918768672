import React from "react";
import UserService from "../../Services/UserService";
import { Flex, Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import EChartsReact from "echarts-for-react";

const PracticeChart = () => {
  const [chartOptions, setChartOptions] = React.useState<any>({ xData: [],yData: [] });
  const [loader, setLoader] = React.useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = React.useState<string>("active"); // Default to "active"

  React.useEffect(() => {
    // Fetch data for the chart
    setLoader(true);
    UserService.getUserDetailsforChart().then((response) => {
        if (response?.result && response?.result.length > 0) {
          applyFilter(selectedFilter, response.result); // Apply the default filter
        }
      })
      .catch((error) => {
        console.error("Error fetching chart data:", error);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [selectedFilter]); // Re-run when filter changes

  //applying filter to get data according to field selected in dropdown
  const applyFilter = (filter: string, data: any[]) => {
    const xData = data.map((ch: any) => `Practice ${ch?.id}`);
    let yData;
    switch (filter) {
      case "active":
        yData = data.map((ch: any) => ch?.active);
        break;
      case "inActive":
        yData = data.map((ch: any) => ch?.inActive);
        break;
      case "deleted":
        yData = data.map((ch: any) => ch?.deleted);
        break;
      default:
        yData = [];
    }
    setChartOptions({ xData, yData });
  };

  return (
    <div style={{ paddingLeft: "20px", paddingTop: "10px" }}>
      <Flex gap="middle">
        <h4
          style={{
            marginTop: "0px",
            marginBottom: "6px",
            fontSize: "16px",
            color: "#3F5BD3",
          }}
        >
          {`Practices and Its ${selectedFilter.charAt(0).toUpperCase() + selectedFilter.slice(1)} Users`}
        </h4>
        <Select
          style={{ width: 150 }}
          defaultValue={{ label: "Active", value: "active" }}
          options={[
            { label: "Active", value: "active" },
            { label: "Inactive", value: "inActive" },
            { label: "Deleted", value: "deleted" },
          ]}
          onChange={(value:any) => {
            setSelectedFilter(value); // Update the filter state
          }}
        />
      </Flex>
      <Spin
        spinning={loader}
        indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
      >
        <EChartsReact
          option={{
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: [
              {
                type: "category",
                data: chartOptions?.xData,
                axisTick: {
                  alignWithLabel: true,
                },
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            series: [
              {
                name: `${selectedFilter.charAt(0).toUpperCase() + selectedFilter.slice(1)} Users`,
                type: "bar",
                barWidth: "50%",
                data: chartOptions?.yData,
              },
            ],
          }}
        />
      </Spin>
    </div>
  );
};

export default PracticeChart;
