import { Col, Form, Input, Modal, Row, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import { adminContext } from "../../Common/PageRoute";
import { useNavigate } from "react-router-dom";
import PracticesService from "../../Services/PracticesService";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Utils } from "../../Utilities/Utils";

const AddPractice = ({ practices, onDismiss }: { id?: any; practices: any; onDismiss: (data?: any) => void }) => {
  const [form] = useForm();
  const navigate = useNavigate();
  const { openNotification } = React.useContext(adminContext);
  const [isCloneSelected, setIsCloneSelected] = React.useState(false); // State to toggle confirmation message

  // Map practices data to Select options
  const practiceOptions = practices?.map((practice: any) => ({
    label: practice.name,
    value: practice.id,
  }));

  return (
    <Modal
      open={true}
      width={600}
      title={"Add Practice"}
      okText={
        <>
          <SaveOutlined /> Save
        </>
      }
      cancelText={
        <>
          <CloseOutlined /> Cancel
        </>
      }
      onCancel={() => onDismiss(false)}
      onOk={(values: any) => {
        form.validateFields().then(async (value) => {
          const cloneId = value?.clonePractice; // Clone Practice ID (optional)
          let res = await PracticesService.addPractices(value, cloneId);
          if (res.status) {
            navigate(`/practices/${res.result.id}`);
            openNotification("success", "Practice added successfully.");
          } else {
            openNotification("error", res.message);
            // onDismiss(true);
          }
        }).catch((error) => {
          openNotification("error", "Validate Fields");
        });
      }}>
      <Form requiredMark={false} form={form}>
        <Row className="px-3 py-1">
          {practices.length > 0 && (
            <Col span={12}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Clone Practice"
                name="clonePractice"
              >
                <Select 
                  options={[{ label: "Select", value: "" }, ...practiceOptions]} // Default option to deselect
                  onChange={(value) => setIsCloneSelected(!!value)} // Update state when an option is selected
                  placeholder="Select a practice to clone from"
                  title="Select a practice to clone from"
                  allowClear
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row className="px-3 py-1">
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="Select Name"
              name="name"
              rules={[{ required: true, message: "Name is required" }, { validator: Utils.validateWhiteSpace }]}
            >
              <Input
                className="line-height-28"
                placeholder="Name"
                onKeyPress={(e: any) => Utils.ValidateCharOnly(e)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="Select Email"
              name="email"
              rules={[
                { required: true, message: "This field is required" },
                { validator: Utils.ValidateEmailFormat },
              ]}
            >
              <Input className="line-height-28" placeholder="Email" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 py-1">
          <Col span={24}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 21 }}
              label="Company Logo Url"
              name="logoUrl"
            >
              <Input className="line-height-28" placeholder="Company Logo Url" />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {isCloneSelected && ( // Show the message if a practice is selected for cloning
        <Row className="px-3 py-1">
          <Col span={24} className="p-3" style={{ backgroundColor: "#eef2ff", borderRadius: 5 }}>
            <Row className="font-bold">Are you sure?</Row>
            <Row>You want to clone details from the selected practice.</Row>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default AddPractice;
