import { EditTwoTone, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Spin, Row, Breadcrumb, Button, Col, Table } from 'antd'
import Search from 'antd/es/transfer/search';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import AddApplicationConfigKey from './AddApplicationConfigKey';
import ApplicationCongfigKeys from '../../Services/ApplicationCongfigKeys';
import Link from 'antd/es/typography/Link';
import { adminContext } from '../../Common/PageRoute';

const ApplicationConfigKey = () => {
    const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [dialog, setDialog] = React.useState<{type :any, params:any}>({ type: "", params: {} });
  const [configList, setConfigList] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const { openNotification } = React.useContext(adminContext);

  const GetTypeName = (type: any) =>{
    switch(type){
      case 0:
      return 'Unknown';
      case 1:
      return 'User';
      case 1:
      return 'Practice';
      case 1:
      return 'Company';
    }
  } 

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
      render: (_: any, record: any, index: any) => <p>{index + 1}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "20%",
      render: (_: any, record: any, index: any) => <p>{GetTypeName(record.type)}</p>,
    },
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      width: "20%",
      render: (_: any, record: any, index: any) => {
      return (
        <p>
          {record.items.map((itm:any, idx:any) => {
            return <span key={idx}>{idx + 1}. {itm.name}, <br></br> </span>;
          })}
        </p>
      )},
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: "7%",
      render: (_: any, record: any, index: any) => (
        <Link
          className="edit-btn"
          onClick={() => {
            console.log("rec", record);
            setDialog({ type: "addAppConfigKey", params: { ...record } });
          }}
        >
          <EditTwoTone />
        </Link>
      ),
    },
  ]

  React.useEffect(() => {
    setLoading(true);
    ApplicationCongfigKeys.getApplicationConfigKeys().then((res:any) => {
      if(res.result) setConfigList(res.result);
      else openNotification(res.message);
    }).finally(()=>{
      setLoading(false);
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  return (
    <>
    <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Row justify="start" align="middle" style={{ marginBottom: "6px" }}>
          <Breadcrumb style={{ color: "#172B4D" }}>
            <Breadcrumb.Item onClick={() => navigate("/ApplicationConfigKey")}>
              App Config Settings
            </Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <Row
          className="roundedCornerSmall p-3"
          style={{ backgroundColor: "white" }}
        >
          <Col span={24}>
            <Row justify="space-between" className="pb-3">
              <Button
                type="primary"
                className="add-btn"
                onClick={() => {
                  setDialog({ type: "addAppConfigKey", params: {} });
                }}
                >
                <PlusOutlined />
                Add New Configration Key
              </Button>
            </Row>
          </Col>
          <Col span={24}>
          <Table
            dataSource={configList?.length > 0 ? configList: []}
            columns={columns}
            size="small"
            className="aoa-custom-table"
            pagination={{
              defaultPageSize:15,
            }}
            loading={{
              spinning: loading,
              indicator: (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                />
              ),
            }}
          />
          </Col>
        </Row>
        {dialog.type === "addAppConfigKey" && (
            <AddApplicationConfigKey 
            record={dialog.params}
            onDismiss={(conformation: boolean) => {
              setDialog({ type: "", params: {} });
              if (conformation) {
                  setRefresh((x) => x = !x);
                }
              }} />
        )}
        </Spin>
    </>
  )
}

export default ApplicationConfigKey