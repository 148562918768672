import {
  Breadcrumb,
  Button,
  Col,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import React from "react";
import {
  EditTwoTone,
  FolderViewOutlined,
  LeftOutlined,
  LoadingOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/es/table/interface";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { adminContext } from "../../Common/PageRoute";
import AddPractice from "./AddPractices";
import PracticesService from "../../Services/PracticesService";
import ViewPracticeDetails from "./VIewPracticeDetails";

const { Search } = Input;
interface ListDataType {
  key: React.Key;
  id: number;
  name: string;
  phoneNumber: string;
  email: string;
  createdBy: any;
  updatedBy: any;
  inActive?:boolean;
}
interface ListData {
  items: ListDataType[];
  totalItems: number;
}
const Practices = () => {
  const { openNotification } = React.useContext(adminContext);
  const navigate = useNavigate();
  const [practiceList, setPracticeList] = React.useState<ListData>();
  const [loading, setLoading] = React.useState(false);
  const [dialog, setDialog] = React.useState<any>({ type: "", params: {} });
  const [pagingItems, setPagingItems] = React.useState({
    totalRecords: 0,
    currentPage: 1,
    pageSize: 15,
  });
  const [listParams, setListParams] = React.useState({
    search: "",
    sortDir: "ascend",
    sortCol: "Name",
    practiceStatus: 0,
  });

  const columns: ColumnsType<ListDataType> = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
      render: (_: any, record: any, index: any) => (
        <p>
          {pagingItems.currentPage > 1
            ? pagingItems.pageSize * (pagingItems.currentPage - 1) + index + 1
            : index + 1}
        </p>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "Name",
      sorter: true,
      width: "50%",
      render: (text: any, record: any) => (
        <Link title={text} to={`/practices/${record.id}`}>
          {text}
        </Link>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "PhoneNumber",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "Email",
    },
    {
      title: "Created By",
      dataIndex: "createdBy.date",
      sorter: true,
      key: "CreatedBy.Date",
      render: (text: any, record: any) => (
        <span>{dayjs(record?.createdBy?.date).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "Id",
      render: (text: any, record: any) => (
        <>
          <Space wrap>
            <Link to={`/practices/${record.id}`} className="edit-btn">
              <EditTwoTone />
            </Link>
            <FolderViewOutlined
              // className="ms-2"
              style={{ color: "#1890ff" }}
              onClick={() => {
                setDialog({
                  type: "ViewPracticeDetails",
                  params: { record: record },
                });
              }}
            />
          <Popconfirm
            title={`Mark practice as ${record.inActive === true ? "active" : "inactive"}`}
            description={`Are you sure you want to mark this practice as ${record.inActive === true ? "active" : "inactive"}?`}
            icon={<QuestionCircleOutlined style={{ color: record.inActive === true ? "green" : "red" }} />}
            placement="rightBottom"
            okText="Yes"
            cancelText="No"
            okButtonProps={{ danger: !record.inActive === true }}
            onConfirm={() => {
              setLoading(true);              
                const newStatus = !record.inActive; // Toggle the current InActive status
                 PracticesService.markAsInactive(record.id, newStatus).then((res)=>{
                   if (res.status) {
                     openNotification("success",`Practice marked as ${newStatus ? "inactive" : "active"} successfully.`);
                     // Refresh the data list to reflect changes
                     getItems((pagingItems.currentPage - 1) * pagingItems.pageSize,pagingItems.pageSize);
                   } else {
                     openNotification("error", res.message || "Failed to update practice status.");
                   }
                 }).catch((err)=> {
                openNotification("error", err || "An error occurred while updating the status.");
              }).finally(()=>setLoading(false))
            }}
          >
            <LeftOutlined
              title={`Mark practice as ${record.inActive === true ? "active" : "inactive"}`}
              style={{ color: record.inActive === true ? "green" : "#1890ff" }}
            />
          </Popconfirm>
          </Space>
        </>
      ),
    },
  ];

  const getItems = async (start: any, length: any) => {
    let res = await PracticesService.getPracticesList(
      start,
      length,
      listParams.search,
      listParams.sortCol,
      listParams.sortDir,
      listParams.practiceStatus
    );
    if (res?.status) {
      setLoading(false);
      var data: ListDataType[] = res?.result?.items.map((item: any) => {
        var itemWithKey = {
          key: item?.id,
          id: item?.id,
          name: item?.name,
          phoneNumber: item?.phoneNumber,
          email: item?.email,
          createdBy: item?.createdBy,
          updatedBy: item?.updatedBy,
          inActive: item?.inActive,
        };
        return itemWithKey;
      });
      setPracticeList({
        totalItems: res?.result?.totalRecords,
        items: data,
      });
    } else {
      openNotification("error", res.message);
      //setNotification(notific);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    setLoading(true);
    getItems(
      (pagingItems.currentPage - 1) * pagingItems.pageSize,
      pagingItems.pageSize
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listParams.search, listParams.practiceStatus]);

  return (
    <>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Row justify="start" align="middle" style={{ marginBottom: "6px" }}>
        <Breadcrumb
          style={{ color: "#172B4D" }}
          separator=">"
          items={[
            {
              title: 'Practices',
              href:'/practices'
            },
          ]}
        />
          {/* <Breadcrumb style={{ color: "#172B4D" }}>
            <Breadcrumb.Item onClick={() => navigate("/practices")}>
              Practices
            </Breadcrumb.Item>
          </Breadcrumb> */}
        </Row>
        <Row
          className="roundedCornerSmall p-3"
          style={{ backgroundColor: "white" }}
        >
          <Col span={24}>
            <Row justify="space-between" className="pb-3">
              <Col span={12}>
                <Button
                  type="primary"
                  className="add-btn"
                  onClick={() => setDialog({ type: "addPractice", params: {} })}
                >
                  <PlusOutlined />
                  Add Practice
                </Button>
              </Col>
              <Col
                span={12}
                style={{ textAlign: "end", alignContent: "center" }}
              >
                <Space wrap>
                  <Search
                    size="small"
                    placeholder="Search..."
                    onSearch={(val: string) =>
                      setListParams({ ...listParams, search: val })
                    }
                    onChange={(e) =>
                      e.target.value === ""
                        ? setListParams({ ...listParams, search: "" })
                        : null
                    }
                    style={{ width: 170 }}
                  />
                  <Select
                    size="small"
                    style={{ width: 100, textAlign: "start" }}
                    options={[
                      { value: 0, label: "Active" },
                      { value: 1, label: "Inactive" },
                    ]}
                    defaultValue={0} // Set Active as the default
                    onChange={(value) => {
                      setListParams({ ...listParams, practiceStatus: value });
                    }}
                  />
                </Space>
              </Col>
            </Row>
          </Col>

          <Col span={24} style={{ minHeight: window.innerHeight - 200 }}>
            <>
              <Table
                dataSource={practiceList?.items}
                columns={columns}
                size="small"
                className="aoa-custom-table"
                scroll={{ x: 700 }}
                pagination={{
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                  current: pagingItems.currentPage,
                  pageSize: pagingItems.pageSize,
                  showSizeChanger: true,
                  total: practiceList?.totalItems,
                  pageSizeOptions: ["15", "25", "50", "100"],
                }}
                onChange={(paging, filter, sort: any) => {
                  sort.order !== undefined &&
                    setListParams({
                      ...listParams,
                      sortDir: sort.order,
                      sortCol: sort.columnKey,
                    });
                  if (
                    paging?.current !== undefined &&
                    paging?.pageSize !== undefined
                  ) {
                    setLoading(true);
                    setPagingItems((p) => {
                      p.currentPage =
                        paging.current !== undefined ? paging.current : 1;
                      p.pageSize =
                        paging.pageSize !== undefined ? paging.pageSize : 15;
                      return p;
                    });
                    getItems(
                      (paging.current - 1) * paging.pageSize,
                      paging.pageSize
                    );
                  }
                }}
              />
            </>
          </Col>
        </Row>
        {dialog.type === "addPractice" && (
          <AddPractice
            practices={practiceList?.items}
            onDismiss={(confirmed: boolean) => {
              setDialog({ type: "", params: {} });
            }}
          />
        )}
        {dialog.type === "ViewPracticeDetails" && (
          <ViewPracticeDetails
            record={dialog.params?.record}
            onDismiss={(confirmed: boolean) => {
              setDialog({ type: "", params: {} });
            }}
          />
        )}
      </Spin>
      {/* <Divider orientation="left">Small Size</Divider> */}
    </>
  );
};

export default Practices;
