import {
  Col,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  SelectProps,
} from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import CTConfiguration from "../../Services/CTConfigurationService";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Utils } from "../../Utilities/Utils";

const AddTaxRates = ({ id, onDismiss, record, addedYears }: any) => {
  // const { openNotification } = React.useContext(adminContext);
  const [form] = useForm();
  const [selYearOptions, setSelYearOptions] = React.useState<SelectProps["options"]>([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (addedYears?.length > 0) {
      const optn: SelectProps["options"] = [];
      let startYear = Math.min(...addedYears);
      let endYear = Math.max(...addedYears) + 10;
      for (let i = startYear; i <= endYear; i++) {
        console.log("years", addedYears); 
        optn.push({
          value: i,
          label: i,
          disabled: addedYears?.includes(i) ?  true  : false,
        });
      }
      setSelYearOptions(optn);
    }
  }, [addedYears]);

  React.useEffect(() => {
    if (id !== undefined) {
      form.setFieldsValue({
        year: record?.year,
        mainRate: record?.mainRate,
        smallProfitRate: record?.smallProfitRate,
        specailRate: record?.specailRate,
        mrLowerLimit: record?.mrLowerLimit,
        mrUpperLimit: record?.mrUpperLimit,
        standardFractionUp: record?.standardFractionUp,
        standardFractionLow: record?.standardFractionLow,
        ringFanceRate: record?.ringFanceRate,
        ringFanceSmallProfitRate: record?.ringFanceSmallProfitRate,
        ringFanceFractionLow: record?.ringFanceFractionLow,
        ringFanceFractionUp: record?.ringFanceFractionUp,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <Modal
      open={true}
      confirmLoading={loading}
      okText={<><SaveOutlined /> Save</>}
      cancelText={<><CloseOutlined /> Cancel</>}
      onOk={() => {
        setLoading(true);
        //var record = form.getFieldsValue();
        form.validateFields().then(async (values:any)=>{
          CTConfiguration.saveTaxrates(values?.year, values).then((res) => {
            if (res.status) {
              onDismiss(true);
              setLoading(false);
            } else {
              setLoading(false);
            }
          });
        }).catch((err) => {
          // openNotification("error", 'Select a year');
          setLoading(false);
        })
      }}
      onCancel={() => onDismiss(false)}
      title={
        id !== undefined ? `Edit Tax Rates (${record?.year})` : "Add Tax Rates"
      }
      width={1000}
      // width={1000}
    >
      <Form form={form} requiredMark={false}>
        <Col
          span={24}
          className="card  pb-5 border-rounded-1 bg-secondary my-4"
        >
          <Row className="px-3">
            <Col span={24}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 6 }}
                label="Year"
                name="year"
                rules={[
                  {
                    required: true,
                    message: "Please select a year",
                  },
                ]}
              >
                <Select
                  placeholder="Select year"
                  disabled={id ? true : false}
                  options={selYearOptions}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 pt-1">
            <Col className="font-bold d-flex" span={24}>
              Rates
              <span
                style={{
                  width: "100%",
                  height: 0,
                  marginTop: 10,
                  marginLeft: 7,
                  paddingLeft: 5,
                  borderBottom: "1px solid #f3f3f3",
                }}
              ></span>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Main Rate"
                name="mainRate"
              >
                <InputNumber
                  controls={false}
                  placeholder="Main Rate"
                  className="line-height-28"
                  prefix="%"
                  onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Small Profit Rate"
                name="smallProfitRate"
              >
                <InputNumber
                  controls={false}
                  placeholder="Small Profit Rate"
                  className="line-height-28"
                  prefix="%"
                  onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Special Rate"
                name="specailRate"
              >
                <InputNumber
                  controls={false}
                  placeholder="Special Rate"
                  className="line-height-28"
                  prefix="%"
                  onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 pt-1">
            <Col className="font-bold d-flex" span={24}>
              Limits
              <span
                style={{
                  width: "100%",
                  height: 0,
                  marginTop: 10,
                  marginLeft: 7,
                  paddingLeft: 5,
                  borderBottom: "1px solid #f3f3f3",
                }}
              ></span>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Marginal Relief Lower Limit"
                name="mrLowerLimit"
              >
                <InputNumber
                  controls={false}
                  placeholder="Marginal Relief Lower Limit"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Marginal Relief Upper Limit"
                name="mrUpperLimit"
              >
                <InputNumber
                  controls={false}
                  placeholder="Marginal Relief Upper Limit"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 pt-1">
            <Col className="font-bold d-flex" span={24}>
              Standard Fraction
              <span
                style={{
                  width: "85%",
                  height: 0,
                  marginTop: 10,
                  marginLeft: 7,
                  paddingLeft: 5,
                  borderBottom: "1px solid #f3f3f3",
                }}
              ></span>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Standard Fraction Up"
                name="standardFractionUp"
              >
                <InputNumber
                  controls={false}
                  placeholder="Standard Fraction Up"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Standard Fraction Low"
                name="standardFractionLow"
              >
                <InputNumber
                  controls={false}
                  placeholder="Standard Fraction Low"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 pt-1">
            <Col className="font-bold d-flex" span={24}>
              Ring Fance
              <span
                style={{
                  width: "90%",
                  height: 0,
                  marginTop: 10,
                  marginLeft: 7,
                  paddingLeft: 5,
                  borderBottom: "1px solid #f3f3f3",
                }}
              ></span>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Ring Fance Rate"
                name="ringFanceRate"
              >
                <InputNumber
                  controls={false}
                  placeholder="Ring Fance Rate"
                  className="line-height-28"
                  prefix="%"
                  onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Ring Fance Small Profit Rate"
                name="ringFanceSmallProfitRate"
              >
                <InputNumber
                  controls={false}
                  placeholder="Ring Fance Small Profit Rate"
                  className="line-height-28"
                  prefix="%"
                  onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 pt-1">
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Ring Fance Fraction Low"
                name="ringFanceFractionLow"
              >
                <InputNumber
                  controls={false}
                  placeholder="Ring Fance Fraction Low"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Ring Fance Fraction Up"
                name="ringFanceFractionUp"
              >
                <InputNumber
                  controls={false}
                  placeholder="Ring Fance Fraction Up"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Form>
    </Modal>
  );
};

export default AddTaxRates;
