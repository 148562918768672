import { CloseOutlined, EditTwoTone, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import { PracticeUpdateTypes } from "../../Services/PracticesService";
import { Utils } from "../../Utilities/Utils";
const Features = ({
  form,
  isEdit,
  setIsEdit,
  OnSaveClick,
  OnCancelClick,
  handleValuesChange,
  isInactive,
}: any) => {
  return (
    <Form form={form} onValuesChange={handleValuesChange}>
      <Col className="card border pb-3 border-rounded-1 bg-secondary ">
        <Row
          className="border-bottom  px-2 py-1 font-bold"
          justify="space-between"
        >
          Features
          <Button
            icon={<EditTwoTone />}
            type="text"
            disabled={isInactive}
            style={{ border: "none" }}
            onClick={() => setIsEdit({ type: PracticeUpdateTypes.features })}
          />
        </Row>
        <Row className="px-3 pt-3">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "calls"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.features ? false : true
                }
                className="line-height-28"
              >
                Calls
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "leadSources"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.features ? false : true
                }
                className="line-height-28"
              >
                Lead Sources
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "linkedinLeads"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.features ? false : true
                }
                className="line-height-28"
              >
                LinkedIn Leads
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "webForms"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.features ? false : true
                }
                className="line-height-28"
              >
                Web Forms
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "dayEnd"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.features ? false : true
                }
                className="line-height-28"
              >
                Days End
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "accounts"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.features ? false : true
                }
                className="line-height-28"
              >
                Accounts
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "corporationTax"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.features ? false : true
                }
                className="line-height-28"
              >
                Corporation tax
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "aml"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.features ? false : true
                }
                className="line-height-28"
              >
                AML
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "chats"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.features ? false : true
                }
                className="line-height-28"
              >
                Chats
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "teams"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.features ? false : true
                }
                className="line-height-28"
              >
                Teams
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "bankStatement"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.features ? false : true
                }
                className="line-height-28"
              >
                Bank Statements
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "payroll"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.features ? false : true
                }
                className="line-height-28"
              >
                Payroll
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "clientDataRequest"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.features ? false : true
                }
                className="line-height-28"
              >
                Client Data Qequest
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "bookkeeping"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.features ? false : true
                }
                className="line-height-28"
              >
                Bookkeeping
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "timeSheet"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.features ? false : true
                }
                className="line-height-28"
              >
                TimeSheet
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "careOf"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.features ? false : true
                }
                className="line-height-28"
              >
                CareOf
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "selfAssessment"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.features ? false : true
                }
                className="line-height-28"
              >
                Self Assessement
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "dms"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.features ? false : true
                }
                className="line-height-28"
              >
                DMS
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "other4"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.features ? false : true
                }
                className="line-height-28"
              >
                Other4
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "other5"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.features ? false : true
                }
                className="line-height-28"
              >
                Other5
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        {isEdit.type === PracticeUpdateTypes.features && (
          <Row className="px-3 pt-3">
            <Button type="primary" onClick={OnSaveClick}>
              <SaveOutlined /> Save
            </Button>
            <Button className="ms-2" onClick={OnCancelClick}>
              <CloseOutlined /> Cancel
            </Button>
          </Row>
        )}
      </Col>
      <Col className="card border pb-3 mt-3 border-rounded-1 bg-secondary ">
        <Row
          className="border-bottom  px-2 py-1 font-bold"
          justify="space-between"
        >
          Practice Properties (Sub Feature)
          <Button
            icon={<EditTwoTone />}
            type="text"
            disabled={isInactive}
            style={{ border: "none" }}
            onClick={() =>
              setIsEdit({ type: PracticeUpdateTypes.praticeproperites })
            }
          />
        </Row>
        <Col className="font-semibold d-flex ps-3 pt-1" span={24}>
          Parameters
          <span
            style={{
              width: "96%",
              height: 0,
              marginTop: 10,
              marginLeft: 7,
              paddingLeft: 5,
              borderBottom: "1px solid #f3f3f3",
            }}
          ></span>
        </Col>
        <Row className="px-3 pt-1 ">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              label="Page track timeout"
              name={["params", "pageTrackTimeout"]}
            >
              <Input
                className="line-height-28"
                placeholder="Page track timeout"
                onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
              />
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              label="Due invoice days"
              name={["params", "dueInvoicesTaskDays"]}
            >
              <Input
                className="line-height-28"
                placeholder="Due invoice days"
                onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
              />
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              label="Task due date days"
              name={["params", "taskDueDateInDays"]}
            >
              <Input
                className="line-height-28"
                placeholder="Task due date days"
                onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Col className="font-semibold d-flex ps-3 pt-1" span={24}>
          Statement of accounts
          <span
            style={{
              width: "88%",
              height: 0,
              marginTop: 10,
              marginLeft: 7,
              paddingLeft: 5,
              borderBottom: "1px solid #f3f3f3",
            }}
          ></span>
        </Col>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "soaIsEnable"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Enable
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "soaIsNotificationEnable"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Notification
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        {form?.getFieldValue(["appSubFeature", "soaIsNotificationEnable"]) && (
          <>
            <Row className="px-3 pt-1 ">
              <Col span={8} className="d-flex">
                <Form.Item
                  className="custom-form-item"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Notify on(day)"
                  name={["appSubFeature", "soaNotificationSettings", "day"]}
                >
                  <Select
                    className="line-height-28"
                    placeholder="Notify on(day)"
                    disabled={
                      isEdit.type === PracticeUpdateTypes.praticeproperites
                        ? false
                        : true
                    }
                    options={[
                      { value: 1, label: "Monday" },
                      { value: 2, label: "Tuesday" },
                      { value: 3, label: "Wednesday" },
                      { value: 4, label: "Thursday" },
                      { value: 5, label: "Friday" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={8} className="d-flex">
                <Form.Item
                  className="custom-form-item"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Notification frequency"
                  name={[
                    "appSubFeature",
                    "soaNotificationSettings",
                    "frequency",
                  ]}
                >
                  <Select
                    className="line-height-28"
                    placeholder="Notification frequency"
                    disabled={
                      isEdit.type === PracticeUpdateTypes.praticeproperites
                        ? false
                        : true
                    }
                    options={[
                      { value: 0, label: "Weekly" },
                      { value: 1, label: "Two-Weekly" },
                      { value: 2, label: "Monthly" },
                      { value: 3, label: "Quarterly" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={8} className="d-flex">
                <Form.Item
                  className="custom-form-item"
                  name={[
                    "appSubFeature",
                    "soaNotificationSettings",
                    "notifyOnLastDay",
                  ]}
                  valuePropName="checked"
                >
                  {/* <Input
                      className="line-height-28"
                      placeholder="Notify at quarter end"
                      onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                      disabled={
                        isEdit.type === PracticeUpdateTypes.praticeproperites
                        ? false
                        : true
                      }
                    /> */}
                  <Checkbox
                    disabled={
                      isEdit.type === PracticeUpdateTypes.praticeproperites
                        ? false
                        : true
                    }
                    className="line-height-28"
                  >
                    Notify at quarter end
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row className="px-3 pt-1 ">
              <Col span={8} className="d-flex">
                <Form.Item
                  className="custom-form-item"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Due amount greater than"
                  name={["appSubFeature", "soaNotificationSettings", "amount"]}
                >
                  <InputNumber
                    controls={false}
                    placeholder="Due amount greater than"
                    prefix="£"
                    onKeyPress={(e) => Utils.ValidateAmountOnly(e)}
                    style={{ width: "200px" }}
                    disabled={
                      isEdit.type === PracticeUpdateTypes.praticeproperites
                        ? false
                        : true
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {form.getFieldValue(["appFeature", "timeSheet"]) && (
          <>
            <Col className="font-semibold d-flex ps-3 pt-1" span={24}>
              Manual timesheets
              <span
                style={{
                  width: "88%",
                  height: 0,
                  marginTop: 10,
                  marginLeft: 7,
                  paddingLeft: 5,
                  borderBottom: "1px solid #f3f3f3",
                }}
              ></span>
            </Col>
            <Row className="px-3 pt-1">
              <Col span={8} className="d-flex">
                <Form.Item
                  className="custom-form-item"
                  name={["appSubFeature", "manualTimesheet"]}
                  valuePropName="checked"
                >
                  <Checkbox
                    disabled={
                      isEdit.type === PracticeUpdateTypes.praticeproperites
                        ? false
                        : true
                    }
                    className="line-height-28"
                  >
                    Manual timesheet
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={8} className="d-flex">
                <Form.Item
                  className="custom-form-item"
                  name={["appSubFeature", "mtsAutomationSettings", "automate"]}
                  valuePropName="checked"
                >
                  <Checkbox
                    disabled={
                      isEdit.type === PracticeUpdateTypes.praticeproperites
                        ? false
                        : true
                    }
                    className="line-height-28"
                  >
                    Automate WIP
                  </Checkbox>
                </Form.Item>
              </Col>
              {form?.getFieldValue([
                "appSubFeature",
                "mtsAutomationSettings",
                "automate",
              ]) && (
                <Col span={8} className="d-flex">
                  <Form.Item
                    className="custom-form-item"
                    label="Days"
                    name={["appSubFeature", "mtsAutomationSettings", "days"]}
                  >
                    <Input
                      className="line-height-28"
                      placeholder="Days"
                      onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                      disabled={
                        isEdit.type === PracticeUpdateTypes.praticeproperites
                          ? false
                          : true
                      }
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </>
        )}
        <Col className="font-semibold d-flex ps-3 pt-1" span={24}>
          Additional features
          <span
            style={{
              width: "88%",
              height: 0,
              marginTop: 10,
              marginLeft: 7,
              paddingLeft: 5,
              borderBottom: "1px solid #f3f3f3",
            }}
          ></span>
        </Col>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "digitalSignatureWithoutAML"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Digital Signature Without AML
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "websiteQueriesWithOTP"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Website Quries With OTP
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "addCustomerWithoutLead"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Add Customer Without Lead
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "progressiveLeads"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Progressive Leads
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "witoutDiscount"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Without Discount
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "actionTab"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Action Tab
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "scheduleEmail"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Schedule Email
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "resourceSection"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Resource section (Reports)
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "clientAutomation"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Client automation
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "proofOfEarningFPS"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Proof of earning (FPS)
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "receiptAttachments"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Receipt Attachments
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "skipTeamUpdate"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Skip team update
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "secureLink"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Send attachments via secure link
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "bankDetails"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Bank Details
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "amLforAllOfficers"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                AML for all officers
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        {/* ---------- */}
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "allowTLandAdminToUpdateTaskDeadline"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Task deadline by team leaderk
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "directorAccountWhileRetrieve"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Directors accounts
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "reviewNotificationToTeamleader"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Review notifications to teamleaders
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        {/* ---------- */}
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "noChangeInTaskAssigneeWithTeamChange"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Change assignee with a change in account manager
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        {/* Client fields */}
        <Col className="font-semibold d-flex ps-3 pt-1" span={24}>
          Client fields
          <span
            style={{
              width: "90%",
              height: 0,
              marginTop: 10,
              marginLeft: 7,
              paddingLeft: 5,
              borderBottom: "1px solid #f3f3f3",
            }}
          ></span>
        </Col>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "firstResponse"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                First response
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "accountManagar"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Account managar
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "accountant"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Accountant
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "mode"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Mode
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "agentCredentialsRemark"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Agent Credentials Remark
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "clientBrand"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Client brand
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "clientProposition"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Client Proposition
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Col className="font-semibold d-flex ps-3 pt-1" span={24}>
          Tax and Accounts
          <span
            style={{
              width: "88%",
              height: 0,
              marginTop: 10,
              marginLeft: 7,
              paddingLeft: 5,
              borderBottom: "1px solid #f3f3f3",
            }}
          ></span>
        </Col>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "s455TaxLiability"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.praticeproperites
                    ? false
                    : true
                }
                className="line-height-28"
              >
                S455 tax liability
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1"></Row>
        {isEdit.type === PracticeUpdateTypes.praticeproperites && (
          <Row className="px-3 pt-3">
            <Button type="primary" onClick={OnSaveClick}>
              <SaveOutlined /> Save
            </Button>
            <Button className="ms-2" onClick={OnCancelClick}>
              <CloseOutlined /> Cancel
            </Button>
          </Row>
        )}
      </Col>
    </Form>
  );
};

export default Features;
