import { CloseOutlined, EditTwoTone, SaveOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, DatePicker, Form, Input, Row } from "antd";
import { PracticeUpdateTypes } from "../../Services/PracticesService";
const ImportantBadges = ({
    form,
    isEdit,
    setIsEdit,
    OnSaveClick,
    OnCancelClick,
    handleValuesChange,
    isInactive,
  }: any) => {
  return (
    <Form form={form} onValuesChange={handleValuesChange}>
    <Col className="card border pb-3 border-rounded-1 bg-secondary ">
      <Row
        className="border-bottom  px-2 py-1 font-bold"
        justify="space-between"
      >
        Badges
        <Button         
          icon={<EditTwoTone />}
          type="text"
          disabled={isInactive}
          style={{border:'none'}}
          onClick={() =>
            setIsEdit({ type: PracticeUpdateTypes.badges })
          }
        />
      </Row>
      <Row className="px-3 pt-3">
        <Col lg={12} sm={12} xs={24}>
          <Form.Item
            className="custom-form-item"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 12 }}
            label="Warning Message"
            name={["badges", "warningMessage"]}
          >
            <Input.TextArea
              disabled={
                isEdit.type === PracticeUpdateTypes.badges ? false : true
              }
              name="warningMessage"
              placeholder="WarningMessage"
            />
          </Form.Item>
          </Col>
          <Col lg={12} sm={12} xs={24}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
              label="Date Range"
              name={["badges", "dateRange"]}
            >
              <DatePicker.RangePicker
                allowEmpty
                placeholder={["From Date","To Date"]}
                format={'DD/MM/YYYY'}
                disabled={isEdit.type === PracticeUpdateTypes.badges ? false : true}
                styles={{popup:{accentColor:'red'}}}
                name="dateRange"
              />
            </Form.Item>
        </Col>
        <Col></Col>
      </Row>
      <Row gutter={[0, 4]} wrap className="px-3 pt-3">
        <Col lg={8}  sm={12} xs={24} className="d-flex">
          <Form.Item
            className="custom-form-item"
            name={["badges", "accountsReport"]}
            valuePropName="checked"
          >
            <Checkbox
              disabled={
                isEdit.type === PracticeUpdateTypes.badges ? false : true
              }
              className="line-height-28"
            >
              Accounts Reports
            </Checkbox>
          </Form.Item>
        </Col>
        <Col lg={8}  sm={12} xs={24} className="d-flex">
          <Form.Item
            className="custom-form-item"
            name={["badges", "corporationTax"]}
            valuePropName="checked"
          >
            <Checkbox
              disabled={
                isEdit.type === PracticeUpdateTypes.badges ? false : true
              }
              className="line-height-28"
            >
              Corporation Tax
            </Checkbox>
          </Form.Item>
        </Col>
        <Col lg={8}  sm={12} xs={24} className="d-flex">
          <Form.Item
            className="custom-form-item"
            name={["badges", "confirmationStatement"]}
            valuePropName="checked"
          >
            <Checkbox
              disabled={
                isEdit.type === PracticeUpdateTypes.badges ? false : true
              }
              className="line-height-28"
            >
              Confirmation Statement
            </Checkbox>
          </Form.Item>
        </Col>
        <Col lg={8}  sm={12} xs={24} className="d-flex">
          <Form.Item
            className="custom-form-item"
            name={["badges", "sA100"]}
            valuePropName="checked"
          >
            <Checkbox
              disabled={
                isEdit.type === PracticeUpdateTypes.badges ? false : true
              }
              className="line-height-28"
            >
              SA100
            </Checkbox>
          </Form.Item>
        </Col>
        <Col lg={8}  sm={12} xs={24} className="d-flex">
          <Form.Item
            className="custom-form-item"
            name={["badges", "sA800"]}
            valuePropName="checked"
          >
            <Checkbox
              disabled={
                isEdit.type === PracticeUpdateTypes.badges ? false : true
              }
              className="line-height-28"
            >
              SA800
            </Checkbox>
          </Form.Item>
        </Col>
        <Col lg={8}  sm={12} xs={24} className="d-flex">
          <Form.Item
            className="custom-form-item"
            name={["badges", "vat"]}
            valuePropName="checked"
          >
            <Checkbox
              disabled={
                isEdit.type === PracticeUpdateTypes.badges ? false : true
              }
              className="line-height-28"
            >
              VAT
            </Checkbox>
          </Form.Item>
        </Col>
        <Col lg={8}  sm={12} xs={24} className="d-flex">
          <Form.Item
            className="custom-form-item"
            name={["badges", "eps"]}
            valuePropName="checked"
          >
            <Checkbox
              disabled={
                isEdit.type === PracticeUpdateTypes.badges ? false : true
              }
              className="line-height-28"
            >
              EPS
            </Checkbox>
          </Form.Item>
        </Col>
        <Col lg={8}  sm={12} xs={24} className="d-flex">
          <Form.Item
            className="custom-form-item"
            name={["badges", "fps"]}
            valuePropName="checked"
          >
            <Checkbox
              disabled={
                isEdit.type === PracticeUpdateTypes.badges ? false : true
              }
              className="line-height-28"
            >
              FPS
            </Checkbox>
          </Form.Item>
        </Col>
        <Col lg={8}  sm={12} xs={24} className="d-flex">
          <Form.Item
            className="custom-form-item"
            name={["badges", "cis"]}
            valuePropName="checked"
          >
            <Checkbox
              disabled={
                isEdit.type === PracticeUpdateTypes.badges ? false : true
              }
              className="line-height-28"
            >
              CIS
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      {isEdit.type === PracticeUpdateTypes.badges && (
        <Row className="px-3 pt-3">
          <Button type="primary" onClick={OnSaveClick}>
            <SaveOutlined /> Save
          </Button>
          <Button className="ms-2" onClick={OnCancelClick}>
            <CloseOutlined /> Cancel
          </Button>
        </Row>
      )}
    </Col>
  </Form>
  )
}

export default ImportantBadges