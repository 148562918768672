import {
  EditTwoTone,
  FolderViewOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Spin, Row, Breadcrumb, Button, Col, Input, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import UserService from "../../Services/UserService";
import { adminContext } from "../../Common/PageRoute";
import AddUser from "./AddUser";

interface ListDataType {
  key: React.Key;
  id: number;
  fullName: string;
  phoneNumber: string;
  email: string;
  createdBy: any;
  updatedBy: any;
}

interface ListData {
  items: ListDataType[];
  totalItems: number;
}
const { Search } = Input;
const AdminUsers = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [dialog, setDialog] = React.useState<{ type: string; params: any }>({
    type: "",
    params: {},
  });
  const [usersList, setUsersList] = React.useState<ListData>();
  const { openNotification } = React.useContext(adminContext);
  const [pagingItems, setPagingItems] = React.useState({
    totalRecords: 0,
    currentPage: 1,
    pageSize: 15,
  });
  const [listParams, setListParams] = React.useState({
    refresh: true,
    search: "",
    sortDir: "ascend",
    sortCol: "Name",
  });

  const columns: ColumnsType<ListDataType> = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
      render: (_: any, record: any, index: any) => (
        <p>
          {pagingItems.currentPage > 1
            ? pagingItems.pageSize * (pagingItems.currentPage - 1) + index + 1
            : index + 1}
        </p>
      ),
    },
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      sorter: true,
      width: "15%",
      render: (text: any, record: any) => (
        <Link title={text} to={`/adminusers/${record.id}`}>
          {record.fullName}
        </Link>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "Email",
      width: "15%",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: "15%",
    },
    {
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
      render: (text: any, record: any) => {
        const uniqueRoles: string[] = [...new Set<string>(record?.roles ?? [])];
        return (
          <>
            {uniqueRoles.map((item: any, idx: any) => {
              return (
                <span key={idx}>
                  {item}
                  {idx + 1 === record?.roles?.length ? "." : ", "}
                </span>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "Id",
      render: (text: any, record: any) => (
        <>
          <Link to={`/adminusers/${record.id}`} className="edit-btn">
            <EditTwoTone />
          </Link>
          {/* <FolderViewOutlined/> */}
        </>
      ),
    },
  ];
  const getItems = async (start: any, length: any) => {
    let res = await UserService.getUsers(
      start,
      length,
      listParams.search,
      listParams.sortCol,
      listParams.sortDir
    );
    if (res?.status) {
      setLoading(false);
      var data: ListDataType[] = res?.result?.items.map((item: any) => {
        var itemWithKey = {
          key: item?.id,
          id: item?.id,
          fullName: item?.fullName,
          roles: item?.roles,
          phoneNumber: item?.phoneNumber,
          email: item?.email,
          createdBy: item?.createdBy,
        };
        return itemWithKey;
      });
      setUsersList({
        totalItems: res?.result?.totalRecords,
        items: data,
      });
    } else {
      openNotification("error", res.message);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    setLoading(true);
    getItems(
      (pagingItems.currentPage - 1) * pagingItems.pageSize,
      pagingItems.pageSize
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listParams]);
  return (
    <>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Row justify="start" align="middle" style={{ marginBottom: "6px" }}>
          <Breadcrumb 
            style={{ color: "#172B4D" }}
            separator=">"
            items={[
              {
                title: 'Admin Users',
                href:'/adminusers'
              },

            ]}
          />
          {/* <Breadcrumb style={{ color: "#172B4D" }}>
            <Breadcrumb.Item onClick={() => navigate("/adminusers")}>
              Admin Users
            </Breadcrumb.Item>
          </Breadcrumb> */}
        </Row>
        <Row
          className="roundedCornerSmall p-3"
          style={{ backgroundColor: "white" }}
        >
          <Col span={24}>
            <Row justify="space-between" className="pb-3">
              <Button
                type="primary"
                className="add-btn"
                onClick={() => setDialog({ type: "userdailog", params: {} })}
              >
                <PlusOutlined />
                Add User
              </Button>
              <Search
                size="small"
                placeholder="Search..."
                className="att-search-input mb-1 me-5"
                onSearch={(val: string) =>
                  setListParams({ ...listParams, search: val })
                }
                onChange={(e) =>
                  e.target.value === ""
                    ? setListParams({ ...listParams, search: "" })
                    : null
                }
                style={{ width: 170 }}
              />
            </Row>
          </Col>
          <Col span={24} style={{ minHeight: window.innerHeight - 200 }}>
            <Table
              dataSource={usersList?.items}
              columns={columns}
              size="small"
              className="aoa-custom-table"
              scroll={{ x: 700 }}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: pagingItems.currentPage,
                pageSize: pagingItems.pageSize,
                showSizeChanger: true,
                total: usersList?.totalItems,
                pageSizeOptions: ["15", "25", "50", "100"],
              }}
              onChange={(paging, filter, sort: any) => {
                sort.order !== undefined &&
                  setListParams({
                    ...listParams,
                    sortDir: sort.order,
                    sortCol: sort.columnKey,
                  });
                if (
                  paging?.current !== undefined &&
                  paging?.pageSize !== undefined
                ) {
                  setLoading(true);
                  setPagingItems((p) => {
                    p.currentPage =
                      paging.current !== undefined ? paging.current : 1;
                    p.pageSize =
                      paging.pageSize !== undefined ? paging.pageSize : 15;
                    return p;
                  });
                  getItems(
                    (paging.current - 1) * paging.pageSize,
                    paging.pageSize
                  );
                }
              }}
            />
          </Col>
        </Row>
      </Spin>
      {dialog.type === "userdailog" && (
        <AddUser
          id={dialog?.params?.id}
          onDismiss={(confirmation: any) => {
            if (confirmation) {
              setListParams({ ...listParams, refresh: !listParams.refresh });
            }
            setDialog({ type: "", params: {} });
          }}
        />
      )}
    </>
  );
};

export default AdminUsers;
