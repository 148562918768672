import React from "react";
import "./Dashboard.css";
import { Breadcrumb, Card, Col, Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import PracticeChart from "./PracticeChart";
import PracticeCard from "./PracticeCard";
import { adminContext } from "../../Common/PageRoute";
import PracticesService from "../../Services/PracticesService";
import SubmissionChart from "./SubmissionChart";
import InvoiceChart from "./InvoiceChart";

const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const { openNotification } = React.useContext(adminContext);
  const [data, setData] = React.useState([]);
  const [practiceIds, setPracticeIds] = React.useState([]);

  React.useEffect(() => {
    setLoading(true);
    PracticesService.getUserData()
      .then((rec) => {
        if (rec?.result && rec?.result.length > 0) {
          setData(rec.result);
          const practiceIds = rec?.result?.map((item: any) => item.id) || [];
          setPracticeIds(practiceIds);
        } else {
          openNotification("error", rec.message || `Can't get practice users`);
        }
      })
      .catch((err) => {
        openNotification("error", err || `Can't get practice users`);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin
      spinning={loading}
      indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
    >
      <Row justify="start" align="middle" style={{ marginBottom: "6px" }}>
      <Breadcrumb
          separator=">"
          items={[
            {
              title: 'Dashboard',
              href:'/dashboard',
            }
          ]}
        />
        {/* <Breadcrumb style={{ color: "#172B4D" }}>
          <Breadcrumb.Item onClick={() => navigate("/dashboard")}>
            Dashboard
          </Breadcrumb.Item>
        </Breadcrumb> */}
      </Row>
      <Row className="roundedCornerSmall" style={{ backgroundColor: "white" }}>
        <Col span={24}>
          <PracticeCard userData={data as any} loading={loading} />
        </Col>
      </Row>
      <Row gutter={[16, 8]} style={{paddingTop:10,marginBottom:10}}>
        <Col span={12}>
          <Card>
            <PracticeChart />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <SubmissionChart practiceIds={practiceIds} />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <InvoiceChart practiceIds={practiceIds} />
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

export default Dashboard;
