import {
  CloseOutlined,
  EditTwoTone,
  LoadingOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Spin,
  Row,
  Breadcrumb,
  Col,
  Button,
  DatePicker,
  Form,
  Input,
  Image,
  Select,
  Typography,
} from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Utils } from "../../Utilities/Utils";
import { useForm } from "antd/es/form/Form";
import { adminContext } from "../../Common/PageRoute";
import UserService from "../../Services/UserService";
import dayjs from "dayjs";

const UserProfile = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = useForm();
  const { openNotification, countriesOptions } = React.useContext(adminContext);
  const [loading, setLoading] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(true);
  const [imagePath, setImagePath] = React.useState("");

  const SetFieldValues = (user: any) => {
    form.setFieldsValue({
      id: user?.id,
      address: user?.address,
      birthDate: user?.birthDate
        ? dayjs(user?.birthDate, "DD/MM/YYYY")
        : undefined,
      email: user?.email,
      fullName: user?.fullName,
      gender: user?.gender,
      name: user?.name,
      phoneNumber: user?.phoneNumber,
      role: user?.role,
      // picture: {
      //   imagePath: user?.picture?.imagePath,
      //   thumbPath: user?.picture?.thumbPath,
      // },
      userName: user?.userName,
    });
  };

  const OnSave = (values: any) => {
    setLoading(true);
    let Values = {
      ...values,
      birthDateStr: dayjs(values?.birthDate).format("DD/MM/YYYY"),
    };
    let Id = id ?? "";
    UserService.updateUserDetails(Id, Values)
      .then((res) => {
        if (res.status) {
          openNotification("success", "Admin details updated.");
          SetFieldValues(res.result);
        }
        else openNotification("error", res.message);
      })
      .finally(() => {
        
        setLoading(false);
        setIsEdit(true);
      });
  };

  React.useEffect(() => {
    if (id !== undefined && id !== null && id !== "") {
      setLoading(true);
      UserService.getUserDetails(id ?? "")
        .then((user: any) => {
          if (user?.status) {
            setImagePath(user?.result?.picture?.imagePath);
            SetFieldValues(user?.result);
          } else {
            openNotification("error", user?.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Row justify="start" align="middle" style={{ marginBottom: "6px" }}>
        <Breadcrumb 
            style={{ color: "#172B4D" }}
            separator=">"
            items={[
              {
                title: 'Admin Users',
                href:'/adminusers'
              },
              {
                title: `${form.getFieldValue("fullName")}`,
                href: `/adminusers/${id}`
              },

            ]}
          />
          {/* <Breadcrumb style={{ color: "#172B4D" }}>
            <Breadcrumb.Item onClick={() => navigate("/adminusers")}>
              Admin Users
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => navigate(`/adminusers/${id}`)}>
            {form.getFieldValue("fullName")}
            </Breadcrumb.Item>
          </Breadcrumb> */}
        </Row>
        <Form form={form} requiredMark={false} onFinish={OnSave}>
          <Row
            className="roundedCornerSmall p-2"
            // style={{ backgroundColor: "white" }}
          >
            <Row
              style={{ width: "100%", minHeight: 750 }}
              justify="space-around"
            >
              <Col span={6} className="bg-white profile-img-col">
                {imagePath !== "" ? (
                  <Image
                    wrapperStyle={{ height: "200px", width: "200px" }}
                    className="rounded profile-img"
                    src={imagePath}
                    alt="Profile image"
                  />
                ) : (
                  <Image
                    wrapperStyle={{ height: "200px", width: "200px" }}
                    className="rounded profile-img"
                    src=""
                    alt="Profile image"
                  />
                )}
                <Row justify="center">
                  <Typography.Title
                    level={5}
                    className="mb-2 pt-2"
                    style={{ margin: 0 }}
                  >
                    {form.getFieldValue("fullName")}
                  </Typography.Title>
                </Row>
                <Row justify="center" className="mb-2">
                  {form.getFieldValue("userName")}
                </Row>
              </Col>
              <Col
                span={17}
                className="bg-white detail-col"
                style={{ position: "relative" }}
              >
                <Row
                  className="border-bottom py-2 ps-4 pe-2 font-bold"
                  justify="space-between"
                >
                  Profile Infiormation
                  <span
                    className="edit-btn"
                    onClick={() => {
                      setLoading(false);
                      setIsEdit(false);
                    }}
                  >
                    <EditTwoTone />
                  </span>
                </Row>
                <Row className="px-4 pt-3">
                  <Col span={12}>
                    <Form.Item
                      className="custom-form-item"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 12 }}
                      label="First Name"
                      name={["name", "first"]}
                      rules={[
                        { required: true, message: "First name is requried" },
                        { validator: Utils.validateWhiteSpace },
                      ]}
                    >
                      <Input
                        disabled={isEdit}
                        className="line-height-28"
                        placeholder="First Name"
                        onKeyPress={(e: any) => Utils.ValidateCharOnly(e)}
                        onInput={(e: any) =>
                          (e.target.value =
                            e.target.value.length > 1
                              ? e.target.value
                              : e.target.value.toUpperCase())
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      className="custom-form-item"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 12 }}
                      label="Last Name"
                      name={["name", "last"]}
                      rules={[
                        { required: true, message: "Last name is requried" },
                        { validator: Utils.validateWhiteSpace },
                      ]}
                      // requiredMark={false}
                    >
                      <Input
                        disabled={isEdit}
                        className="line-height-28"
                        placeholder="Last Name"
                        onKeyPress={(e: any) => Utils.ValidateCharOnly(e)}
                        onInput={(e: any) =>
                          (e.target.value =
                            e.target.value.length > 1
                              ? e.target.value
                              : e.target.value.toUpperCase())
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="px-4 pt-1">
                  <Col span={12}>
                    <Form.Item
                      className="custom-form-item"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 12 }}
                      label="Gender"
                      name="gender"
                      rules={[
                        { required: true, message: "This field is required" },
                      ]}
                    >
                      <Select
                        disabled={isEdit}
                        className="line-height-28"
                        placeholder="gender"
                        options={[
                          { value: 0, label: "Select" },
                          { value: 1, label: "Male" },
                          { value: 2, label: "Female" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      className="custom-form-item"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 12 }}
                      label="Birthdate"
                      name="birthDate"
                      rules={[
                        { required: true, message: "This field is required" },
                        { validator: Utils.validateBirthdate },
                      ]}
                      // getValueProps={((i:any)=>({ value: dayjs(i)}))}
                      // getValueFromEvent={(onChange) => dayjs(onChange).format("DD/MM/YYYY")}
                    >
                      <DatePicker
                        disabled={isEdit}
                        style={{ width: "100%" }}
                        className="line-height-28"
                        placeholder="Birthdate"
                        format="DD/MM/YYYY"
                        //defaultValue={dayjs(userProfile?.birthDate, "DD/MM/YYYY")}
                      />
                      {/* <Input
                      disabled={isEdit}
                      className="line-height-28"
                      placeholder="Birthdate"
                    /> */}
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="px-4 pt-1">
                  <Col span={12}>
                    <Form.Item
                      className="custom-form-item"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 12 }}
                      label="Email"
                      name="email"
                      rules={[
                        { required: true, message: "This field is required" },
                        { validator: Utils.ValidateEmailFormat },
                      ]}
                    >
                      <Input
                        disabled={isEdit}
                        className="line-height-28"
                        placeholder="Email"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      className="custom-form-item"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 12 }}
                      label="Phone Number"
                      name="phoneNumber"
                      rules={[
                        { required: true, message: "This field is required" },
                        {
                          pattern: /^[0-9]{10}$/,
                          message: "Mobile No. must be 10 digits",
                        },
                      ]}
                    >
                      <Input
                        disabled={isEdit}
                        className="line-height-28"
                        placeholder="Phone Number"
                        onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                        maxLength={10}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="px-4 pt-1">
                  <Col span={12}>
                    <Form.Item
                      className="custom-form-item"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 12 }}
                      label="Role"
                      name="role"
                      rules={[
                        { required: true, message: "This field is required" },
                      ]}
                    >
                      <Select
                        disabled={isEdit}
                        className="line-height-28"
                        placeholder="Role"
                        options={[
                          { value: "SuperAdmin", label: "Super Admin" },
                          { value: "SubAdmin", label: "Sub Admin" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}></Col>
                </Row>
                <Row className="font-bold ps-4 py-3">Address Information</Row>
                <Row className="px-4 ">
                  <Col span={12}>
                    <Form.Item
                      className="custom-form-item"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 12 }}
                      label="Building"
                      name={["address", "building"]}
                    >
                      <Input
                        disabled={isEdit}
                        className="line-height-28"
                        placeholder="Building"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      className="custom-form-item"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 12 }}
                      label="Street"
                      name={["address", "street"]}
                    >
                      <Input
                        disabled={isEdit}
                        className="line-height-28"
                        placeholder="Street"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="px-4 pt-1">
                  <Col span={12}>
                    <Form.Item
                      className="custom-form-item"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 12 }}
                      label="City"
                      name={["address", "city"]}
                    >
                      <Input
                        disabled={isEdit}
                        className="line-height-28"
                        placeholder="City"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      className="custom-form-item"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 12 }}
                      label="County"
                      name={["address", "county"]}
                    >
                      <Input
                        disabled={isEdit}
                        className="line-height-28"
                        placeholder="County"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="px-4 pt-1">
                  <Col span={12}>
                    <Form.Item
                      className="custom-form-item"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 12 }}
                      label="Country"
                      name={["address", "country"]}
                    >
                      <Select
                        disabled={isEdit}
                        className="line-height-28"
                        placeholder="Country"
                        options={countriesOptions}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      className="custom-form-item"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 12 }}
                      label="Post Code"
                      name={["address", "postcode"]}
                    >
                      <Input
                        disabled={isEdit}
                        className="line-height-28"
                        placeholder="Post Code"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {!isEdit && (
                  <Row
                    className="pe-5 pb-4 mb-0"
                    style={{ position: "absolute", bottom: 0, right: 0 }}
                    justify={"end"}
                  >
                    <Button
                      className=""
                      onClick={() => {
                        setIsEdit(true);
                        //   SetFieldValues(userProfile);
                      }}
                    >
                      <CloseOutlined /> Cancel
                    </Button>
                    <Button type="primary" htmlType="submit" className="ms-2">
                      <SaveOutlined /> Save
                    </Button>
                  </Row>
                )}
              </Col>
            </Row>
          </Row>
        </Form>
      </Spin>
    </>
  );
};

export default UserProfile;
