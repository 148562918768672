import { CloseOutlined, EditTwoTone, SaveOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { PracticeUpdateTypes } from "../../Services/PracticesService";
import { Utils } from "../../Utilities/Utils";
const PracticeSequence = ({
  form,
  isEdit,
  setIsEdit,
  OnSaveClick,
  OnCancelClick,
  handleValuesChange,
  isInactive,
}: any) => {
  return (
    <Form form={form} onValuesChange={handleValuesChange}>
      <Col className="card border pb-3 border-rounded-1 bg-secondary mb-4">
        <Row
          className="border-bottom  px-2 py-1 font-bold"
          justify="space-between"
        >
          Practice Sequence
          <Button
            icon={<EditTwoTone />}
            type="text"
            disabled={isInactive}
            style={{ border: "none" }}
            onClick={() =>
              setIsEdit({ type: PracticeUpdateTypes.praticesequences })
            }
          />
        </Row>
        <Row>
          <Col xxl={10} xl={12}>
            <Row className="font-semibold ps-3 pt-1">Invoice</Row>
            <Col className="px-3 pt-1">
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 12 }}
                label="Sequence Type"
                name={["sequences", "invoice", "type"]}
              >
                <Select
                  className="line-height-28"
                  placeholder="Sequence Type"
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticesequences
                      ? false
                      : true
                  }
                  options={[
                    { value: 0, label: "None" },
                    { value: 1, label: "Yearly" },
                    { value: 2, label: "Monthly" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Row className="px-3">
              <Col span={4}>
                <Form.Item
                  className="custom-form-item"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Seperator"
                  name={["sequences", "invoice", "seperator"]}
                >
                  <Input
                    className="line-height-28"
                    disabled={
                      isEdit.type === PracticeUpdateTypes.praticesequences
                        ? false
                        : true
                    }
                    onKeyPress={(e) => Utils.ValidateCharAndNumberOnly(e)}
                  />
                </Form.Item>
              </Col>
              <span
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  fontWeight: 900,
                  padding: "0 7px 10px",
                }}
              >
                -
              </span>
              <Col span={5}>
                <Form.Item
                  className="custom-form-item"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Leading Zero"
                  name={["sequences", "invoice", "leadingZero"]}
                >
                  <Input
                    className="line-height-28"
                    onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                    disabled={
                      isEdit.type === PracticeUpdateTypes.praticesequences
                        ? false
                        : true
                    }
                  />
                </Form.Item>
              </Col>
              <span
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  fontWeight: 900,
                  padding: "0 7px 10px",
                }}
              >
                -
              </span>
              <Col span={3}>
                <Form.Item
                  className="custom-form-item"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Prefix"
                  name={["sequences", "invoice", "prefix"]}
                >
                  <Input
                    className="line-height-28"
                    onKeyPress={(e) => Utils.ValidateCharAndNumberOnly(e)}
                    disabled={
                      isEdit.type === PracticeUpdateTypes.praticesequences
                        ? false
                        : true
                    }
                  />
                </Form.Item>
              </Col>
              <span
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  fontWeight: 900,
                  padding: "0 7px 10px",
                }}
              >
                -
              </span>
              <Col span={3}>
                <Form.Item
                  className="custom-form-item"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Postfix"
                  name={["sequences", "invoice", "postfix"]}
                >
                  <Input
                    className="line-height-28"
                    onKeyPress={(e) => Utils.ValidateCharAndNumberOnly(e)}
                    disabled={
                      isEdit.type === PracticeUpdateTypes.praticesequences
                        ? false
                        : true
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xxl={10} xl={12}>
            <Row className="font-semibold ps-3 pt-1">Quotes</Row>
            <Col className="px-3 pt-1">
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 12 }}
                label="Sequence Type"
                name={["sequences", "quote", "type"]}
              >
                <Select
                  className="line-height-28"
                  placeholder="Sequence Type"
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticesequences
                      ? false
                      : true
                  }
                  options={[
                    { value: 0, label: "None" },
                    { value: 1, label: "Yearly" },
                    { value: 2, label: "Monthly" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Row className="px-3 ">
              <Col span={4}>
                <Form.Item
                  className="custom-form-item"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Seperator"
                  name={["sequences", "quote", "seperator"]}
                >
                  <Input
                    className="line-height-28"
                    onKeyPress={(e) => Utils.ValidateCharAndNumberOnly(e)}
                    disabled={
                      isEdit.type === PracticeUpdateTypes.praticesequences
                        ? false
                        : true
                    }
                  />
                </Form.Item>
              </Col>
              <span
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  fontWeight: 900,
                  padding: "0 7px 10px",
                }}
              >
                -
              </span>
              <Col span={5}>
                <Form.Item
                  className="custom-form-item"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Leading Zero"
                  name={["sequences", "quote", "leadingZero"]}
                >
                  <Input
                    className="line-height-28"
                    onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                    disabled={
                      isEdit.type === PracticeUpdateTypes.praticesequences
                        ? false
                        : true
                    }
                  />
                </Form.Item>
              </Col>
              <span
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  fontWeight: 900,
                  padding: "0 7px 10px",
                }}
              >
                -
              </span>
              <Col span={3}>
                <Form.Item
                  className="custom-form-item"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Prefix"
                  name={["sequences", "quote", "prefix"]}
                >
                  <Input
                    className="line-height-28"
                    onKeyPress={(e) => Utils.ValidateCharAndNumberOnly(e)}
                    disabled={
                      isEdit.type === PracticeUpdateTypes.praticesequences
                        ? false
                        : true
                    }
                  />
                </Form.Item>
              </Col>
              <span
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  fontWeight: 900,
                  padding: "0 7px 10px",
                }}
              >
                -
              </span>
              <Col span={3}>
                <Form.Item
                  className="custom-form-item"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Postfix"
                  name={["sequences", "quote", "postfix"]}
                >
                  <Input
                    className="line-height-28"
                    onKeyPress={(e) => Utils.ValidateCharAndNumberOnly(e)}
                    disabled={
                      isEdit.type === PracticeUpdateTypes.praticesequences
                        ? false
                        : true
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        {isEdit.type === PracticeUpdateTypes.praticesequences && (
          <Row className="px-3 pt-3">
            <Button type="primary" onClick={OnSaveClick}>
              <SaveOutlined /> Save
            </Button>
            <Button className="ms-2" onClick={OnCancelClick}>
              <CloseOutlined /> Cancel
            </Button>
          </Row>
        )}
      </Col>
    </Form>
  );
};

export default PracticeSequence;
