import React, { useState } from "react";
//import LoginImg from '../../images/LoginIllustrationNew.svg';
import newLogoImg from "../../images/actingofficelogo.svg";
import { Button, Col, Form, Input, Row, message } from "antd";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../Services/AuthService";
import { useUser } from "../../Context/UserContext";
import { UserOutlined } from "@ant-design/icons";

const Login: React.FC = () => {
  const { setCurrentUser } = useUser();
  const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const UserLogin = () => {
    form
      .validateFields()
      .then(async (val) => {
        setBtnLoading(true);
        await AuthService.login(val.username.trim(), val.password.trim()).then(
          (user: any) => {
            if (user?.token != null) {
              //update usercontext value before switching to dashboard
              setCurrentUser(user);
              navigate("/dashboard");
            }
            if (user == null) {
              setBtnLoading(false);
              throw message.error("Invalid username or password");
            }
          }
        );
      })
      .catch(() => {
        return false;
      });
  };

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      UserLogin();
    }
  };

  return (
    <Row align="middle" className="h100vh">
      <Col
        span={12}
        style={
          window.innerWidth < 992 ? { display: "none" } : { display: "block" }
        }
      >
        <Row>
          <Col xl={20} lg={23}>
            <Row justify="end">
              <img
                style={{ width: "35vw" }}
                src="https://www.actingoffice.com/wp-content/themes/actingoffice/assets/images/home/main.svg"
                alt="loginImg"
              />
            </Row>
          </Col>
        </Row>
      </Col>

      <Col
        xs={24}
        sm={24}
        md={24}
        lg={12}
        xl={12}
        style={{ backgroundColor: "#fff", height: "100%" }}
      >
        <Row className="h100" align="middle">
          <Col xxl={16} xl={18} lg={20} md={17} sm={18} xs={21}>
            <Row align="middle" justify="end">
              <Col
                xl={14}
                lg={16}
                md={13}
                sm={15}
                xs={21}
                className="pb-5 border-bottom"
                style={{textAlign:'center'}}
              >
                <img
                  src={newLogoImg}
                  height="86px"
                  className="cursor"
                  alt="Logo"
                />
              </Col>
              <Col
                xl={14}
                lg={16}
                md={13}
                sm={15}
                xs={21}
                className="pt-4 pb-5"
              >
                <span
                  style={{ color: "#223b95", fontSize: 18, fontWeight: 500 }}
                >
                  Log in to your account
                </span>
              </Col>
              <Col xl={14} lg={16} md={13} sm={15} xs={21}>
                <Form
                  form={form}
                  name="login"
                  initialValues={{ remember: true }}
                  className="login-form"
                  onKeyDown={handleKeyDown}
                >
                  <Row>
                    <Col span={24} className="loginnames mb-1">
                      Username
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="username"
                        rules={[
                          { required: true, message: "Please enter username!" },
                        ]}
                      >
                        <Input
                          suffix={
                            <UserOutlined style={{ color: "#00000073" }} />
                          }
                          onKeyPress={(e) => e.key === ' ' && e.preventDefault()}
                          className="loginInput"
                          autoComplete="on"
                          autoFocus
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} className="loginnames mb-1">
                      Password
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="password"
                        rules={[
                          { required: true, message: "Please enter password!" },
                        ]}
                      >
                        <Input.Password
                          type="password"
                          className="loginInput"
                          autoComplete="on"
                          onKeyPress={(e) => e.key === ' ' && e.preventDefault()}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ margin: "0px 0px 20px 0px" }} justify="end">
                    {/*<Col span={12}>*/}
                    {/*    <Form.Item name="remember" valuePropName="checked" noStyle>*/}
                    {/*        <Checkbox className="lgname">Remember me</Checkbox>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    {/*<Col>*/}
                    {/*    <Link to="/forgot-password">Forgot password?</Link>*/}
                    {/*</Col>*/}
                  </Row>
                  <Form.Item>
                    <Row justify="center" className="pb-5 border-bottom">
                      <Col>
                        <Button
                          type="primary"
                          loading={btnLoading}
                          onClick={() => UserLogin()}
                        >
                          LOG IN
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Login;
