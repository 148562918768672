import { CloseOutlined, EditTwoTone, SaveOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Row } from "antd";
import { PracticeUpdateTypes } from "../../Services/PracticesService";
const ReviewRequest = ({
  form,
  isEdit,
  setIsEdit,
  OnSaveClick,
  OnCancelClick,
  handleValuesChange,
  isInactive,
}: any) => {
  return (
    <Form form={form} onValuesChange={handleValuesChange}>
      <Col className="card border pb-3 border-rounded-1 bg-secondary ">
        <Row
          className="border-bottom  px-2 py-1 font-bold"
          justify="space-between"
        >
          Review Request
          <Button
            icon={<EditTwoTone />}
            type="text"
            disabled={isInactive}
            style={{ border: "none" }}
            onClick={() =>
              setIsEdit({ type: PracticeUpdateTypes.reviewRequest })
            }
          />
        </Row>
        <Row gutter={[0, 4]} wrap className="px-3 pt-3">
          <Col lg={8} sm={12} xs={24} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "reviewRequestConfig", "apct"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.reviewRequest
                    ? false
                    : true
                }
                className="line-height-28"
              >
                Accounts Reports & Corporation Tax
              </Checkbox>
            </Form.Item>
          </Col>
          <Col lg={8} sm={12} xs={24} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "reviewRequestConfig", "sA100"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.reviewRequest
                    ? false
                    : true
                }
                className="line-height-28"
              >
                SA100
              </Checkbox>
            </Form.Item>
          </Col>

          <Col lg={8} sm={12} xs={24} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "reviewRequestConfig", "sA800"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.reviewRequest
                    ? false
                    : true
                }
                className="line-height-28"
              >
                SA800
              </Checkbox>
            </Form.Item>
          </Col>
          <Col lg={8} sm={12} xs={24} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appSubFeature", "reviewRequestConfig", "vat"]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  isEdit.type === PracticeUpdateTypes.reviewRequest
                    ? false
                    : true
                }
                className="line-height-28"
              >
                VAT
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        {isEdit.type === PracticeUpdateTypes.reviewRequest && (
          <Row className="px-3 pt-3">
            <Button type="primary" onClick={OnSaveClick}>
              <SaveOutlined /> Save
            </Button>
            <Button className="ms-2" onClick={OnCancelClick}>
              <CloseOutlined /> Cancel
            </Button>
          </Row>
        )}
      </Col>
    </Form>
  );
};

export default ReviewRequest;
